import { useCallback, useContext, useRef } from 'react'

import { ToastContext, ToastMessageType, ToastProviderProps } from './ToastProvider'

export const useErrorToast = (showRepeatedErrors?: boolean) => {
  const { showToast } = useContext(ToastContext) as ToastProviderProps

  const errorRef = useRef<string>()

  return useCallback(
    (errorMessage?: string) => {
      if (errorRef.current !== errorMessage || showRepeatedErrors) {
        errorRef.current = errorMessage
        if (errorMessage) {
          showToast(errorMessage, ToastMessageType.error)
        }
      }
    },
    [showRepeatedErrors, showToast]
  )
}
