import { useCallback, useContext, useEffect, useState } from 'react'
import { Text, Toast as CapsuleToast } from '@truepill/react-capsule'

import { ToastContext, ToastData, ToastMessageType, ToastProviderProps } from './ToastProvider'

type ToastState = 'error' | 'success' | 'info'

export const Toast = () => {
  const { toastSlices } = useContext(ToastContext) as ToastProviderProps

  if (toastSlices.length) {
    return (
      <>
        {toastSlices.map(toastSlice => (
          <Slice key={toastSlice.id} {...toastSlice} />
        ))}
      </>
    )
  }

  return null
}

const Slice = ({ message, type }: ToastData) => {
  const [showToast, setShowToast] = useState(true)
  const [toastState, setToastState] = useState<ToastState>()

  const getToastState = useCallback(() => {
    const index = Object.values(ToastMessageType).indexOf(type as unknown as ToastMessageType)
    setToastState(Object.keys(ToastMessageType)[index] as ToastState)
  }, [type])

  useEffect(() => {
    getToastState()
  }, [getToastState])

  const onDismissHandler = () => {
    setShowToast(false)
  }

  return (
    <CapsuleToast
      position={{ vertical: 'top', horizontal: 'center' }}
      color="pastel"
      visible={showToast}
      onDismiss={onDismissHandler}
      onTimeout={onDismissHandler}
      state={toastState}
    >
      <Text>{message}</Text>
    </CapsuleToast>
  )
}
