import { Option } from '../../types'
import { DefaultSearchBy } from '../../generated/graphql'
import { CustomerProviderProps } from './types'

export const OPTUM_CUSTOMER = 'optum'
export const CALIBRATE_CUSTOMER = 'calibrate'
export const MARK_CUBAN_CUSTOMER = 'mark_cuban'
export const ASTELLAS_CUSTOMER = 'astellas'
export const SOUTHERN_SCRIPTS_LC_CUSTOMER = 'southern_scripts_lc'
export const SOUTHERN_SCRIPTS_CUSTOMER = 'southern_scripts'
export const VPP_CUSTOMER = 'vpp-test-client'

export const searchOptions: Option<DefaultSearchBy>[] = [
  { label: 'Insurance', value: DefaultSearchBy.Insurance },
  { label: 'Email', value: DefaultSearchBy.Email },
  { label: 'Name', value: DefaultSearchBy.Name },
  { label: 'Order', value: DefaultSearchBy.Order },
]

export const DEFAULT_CUSTOMER = {
  customerId: '87',
  customerName: 'Optum',
  customerSlug: 'optum',
}

export const DEFAULT_CONTEXT: CustomerProviderProps = {
  saveCurrentCustomer: DEFAULT_CUSTOMER,
  handleChangeCustomer: () => undefined,
  customersMap: new Map(),
  customerCollection: [],
  customerUiConfig: null,
  optionByCustomer: [searchOptions[0]],
}
