import { findTracking, TrackingNumber } from 'ts-tracking-number'

type TParseTrackingParams = { trackingUrl: string; shippingMethod?: string }

const defaultTrackingData = {
  courier: {
    code: '',
    name: '',
  },
  description: '',
  name: '',
  trackingNumber: 'Tracking',
  trackingUrl: '',
}

const constructShippingMethodName = (method: string): string => {
  if (!method) return ''
  return method
    .split('_')
    .map(word => {
      if (word === 'usps') return word.toUpperCase()
      else {
        return word[0].toUpperCase() + word.substring(1, word.length)
      }
    })
    .join(' ')
}

type CustomTrackingUrl = { trackingUrl: string }

export const parseTracking = ({
  trackingUrl = '',
  shippingMethod = '',
}: TParseTrackingParams): TrackingNumber & CustomTrackingUrl => {
  const formattedName = shippingMethod ? constructShippingMethodName(shippingMethod) : ''

  const defaultTracking: TrackingNumber & CustomTrackingUrl = {
    ...defaultTrackingData,
    name: shippingMethod && constructShippingMethodName(shippingMethod),
    trackingUrl,
  }

  if (!trackingUrl) {
    return defaultTracking
  }

  const shippingServices = findTracking(trackingUrl)

  if (!shippingServices.length) {
    return defaultTracking
  }

  if (Array.isArray(shippingServices) && shippingServices.length > 1) {
    const sameTracking = shippingServices.find(service => trackingUrl.split('=').includes(service.trackingNumber))

    if (sameTracking) {
      return {
        ...sameTracking,
        trackingUrl,
        // rewrite shipping service name
        name: formattedName,
      }
    } else {
      return { ...defaultTracking, trackingNumber: trackingUrl }
    }
  }

  return {
    ...shippingServices[0],
    trackingUrl,
    // rewrite shipping service name
    name: formattedName,
  }
}
