import { FunctionComponent } from 'react'

export type SurescriptsNumber = number | '.'

export const gender = ['female', 'male', 'unknown']

// Keys that could be included in request headers or response bodies
// that we want to sanitize before sending to logrocket
export const SanitizedPHIKeys = [
  'firstName',
  'lastName',
  'dob',
  'gender',
  'address',
  'phone',
  'email',
  'sms',
  'patientName',
]

export interface Payment {
  paymentMethodToken: string
  isDefault: boolean
  expireMonth: string
  expireYear: string
  cardBrand: string
  cardLastFourDigits: string
  paymentType: PaymentType
  promotionName?: string
  cost: number
  otc: boolean
  shipping: string
  signatureRequired: boolean
  billingAddress: {
    city: string
    country: string
    line1: string
    line2: string
    postalCode: string
    state: string
  }
}

export interface User {
  id: string
  firstName?: string
  lastName?: string
  dob?: string
  email: string
  mobileNumber?: string
  countryCode?: string
  client?: string
  street1?: string
  street2?: string
  city?: string
  state?: string
  zip?: string
  patientToken: string
  addresses?: Address[]
  payments?: [Payment]
  createdAt: Date
  updatedAt?: Date
  username: string
  roles?: string[]
  disabled: boolean
  location?: Location
  locationId: string
  bin?: string
  orders: CustomerOrderObject[]
  qr_url?: string
  picture: string
}
export interface Location {
  _id: string
  name: string
  legacyId: number
  address: Address
  dba?: string
  npi?: string
  ncpdpid?: string
  pharmacistInChargeId?: string
  pharmacistInCharge: User | null
  corporateName?: string
  users: User[]
  contacts?: {
    phone?: string
    fax?: string
  }
}

export interface Customer {
  _id: string
  name: string
}

export interface Address {
  id?: string
  addressLine1?: string
  addressLine2?: string
  address1?: string
  address2?: string
  street1?: string
  street2?: string
  city?: string
  state?: string
  zip?: string
  firstName?: string
  lastName?: string
  isDefault?: boolean
}

export enum OrderStatus {
  copayPending = 'CopayPending',
  pv1 = 'PV1',
  preAdjudication = 'PreAdjudication',
  adjudication = 'Adjudication',
  priorAuthorization = 'PriorAuthorization',
  fill = 'Fill',
  automation = 'Automation',
  pv2 = 'PV2',
  packing = 'Packing',
  partialshipped = 'PartialShipped',
  shipped = 'Shipped',
  complete = 'Complete',
  returned = 'Returned',
  cancelled = 'Cancelled',
}

export enum RejectionReason {
  'Name mismatch' = '62',
  'DOB mismatch' = '09',
  'Bad address details' = 'R2',
  'Refill too soon' = '79',
  'No refills/quantity remaining' = 'NR',
  'Mail order fills exceeded' = 'R010',
  'Duplicate Order' = 'R1',
  'No e-script' = 'PC',
  'Cancellation request' = 'R0',
  'High copay' = 'R009',
  'Test order' = 'R017',
  'Transfer out of pharmacy' = 'R5',
  'Bad insurance details' = '12',
  'Closed network' = 'R003',
  'Coverage not effective' = 'R004',
  'Out of stock' = 'R3',
  'Unavailable to order' = 'R3',
  'Contraindication (drug, disease, allergy)' = 'TEMPTEMPCONTRA',
}

export interface Survey {
  allergies: string
  conditions: string
  medications: string
}

export interface Lock {
  _id: string
  user: User
  userId: string
  expiresAt: number
}

export enum PV1TriageReasons {
  DDI = 'DDI',
  DrugAllergy = 'Drug allergy',
  DrugDisease = 'Drug disease',
  Contraindication = 'Contraindication',
  RxClarification = 'Rx clarification',
  RxChange = 'Rx change',
  DoseClarification = 'Dose clarification (sig or strength)',
  DUR = 'DUR',
  PatientNameDOBMismatch = 'Patient name/dob mismatch',
  WrongSig = 'Wrong sig',
  WrongDaySupply = 'Wrong day supply',
  WrongMD = 'Wrong MD',
  WrongDrug = 'Wrong drug',
  WrongPatient = 'Wrong patient',
  WrongQuantity = 'Wrong quantity',
  WrongPayerOrPaymentMethod = 'Wrong payer/payment method',
  Other = 'Other',
}

export enum FillTriageReasons {
  OOS = 'Out of stock',
}

export enum PV2TriageReasons {
  WrongQuantityFilled = 'Wrong quantity filled',
  WrongDrugLabeled = 'Wrong drug labeled',
  WrongPatient = 'Wrong patient',
}

export enum ShippingTriageReasons {
  AddressIssues = 'Address issues (Address Formatting)',
  ShippingPriorityChange = 'Shipping priority change',
  OutboundScanDuplicates = 'Outbound scan (Duplicates)',
}

export type TriageReasonsType = PV1TriageReasons | FillTriageReasons | PV2TriageReasons | ShippingTriageReasons

export interface CustomerOrderObject {
  address: Address
  order: CustomerOrder
  user: User
}

export interface CustomerOrder {
  products: [OTCProduct]
  cardNumber: string
  createdAt: Date
  discount: number
  shippingMethod: number
  shippingMethodName: number
  shippingPrice: number
  status: string
  taxesPrice: number
  totalPrice: number
  trackingUrl: string
  orderId: string
}

export interface Order {
  _id: string
  createdAt: string
  logs: Log[]
  customer: Customer
  customerId: string
  locationId: string
  notes?: string
  totalNumPrescriptions: number
  patient?: Patient
  guestPatient?: GuestPatient
  rxFillRequests: RXFillRequest[]
  survey: Survey
  coreOrderId: string
  coreOrderToken: string
  customerRef?: string // OTC Order Id
  durInputs: DURSubjectInputs
  inTriage?: OrderTriage
  location: Location
  status: OrderStatus
  lock?: Lock
  triages: OrderTriage[]
  otcProducts: OTCProduct[]
  shippingAddress: Address
  shippingMethod: ShippingMethod
  pricing?: {
    tax: number
    total: number
  }
  customerReturns: CustomerReturn[]
  shipments?: Shipment[]
  hasShippingLabel: boolean
  insurances: OrderInsurance[]
  insuranceOptions: Insurance[]
  apiRequest?: string
  isReplacement?: boolean
  paymentType?: PaymentType
}

export interface CustomerReturn {
  otcProducts: {
    _id: string
    quantity: number
    sku: string
    otcProductId: OTCProduct['_id']
  }[]
  reason: string
  action: string
  status: string
  shippingCharge: string
  carrierTrackingNumber?: string
}

export interface GuestPatient {
  _id: string
  firstName: string
  lastName: string
  contacts?: {
    phone?: string
    email?: string
    sms?: string
  }
}

export interface Shipment {
  trackingNumber: string
}

export interface OTCProduct {
  _id: string
  name?: string
  sku: string
  quantity: number
  daysSupply?: number
  bundleId?: string
  barcode?: string
  image?: Document
  quantityShipped?: number
  bundleItemPartialReturnAllowed?: boolean
  otcStatus: string
  pricing: {
    cost: number
    tax: number
    total: number
  }
  trackingNumber?: string
  trackingUrl?: string
}

export interface RxChangeTriage {
  reason: string
  details?: string[]
  ndc?: string
}
export interface OrderTriage {
  userPlacedInTriage: User
  userRemovedFromTriage?: User
  reason: TriageReasonsType
  inTriageUserMessage?: string
  removeFromTriageUserMessage?: string
  startDate: string
  endDate?: string
  rxFillRequestId: string
  fillId?: string
  rxChange?: RxChangeTriage
}

export type Tag = string

export interface Message {
  _id: string
  createdAt: Date
  message: string
  user: User
  tags: Tag[]
}
export enum NoteResourceEnum {
  ORDER = 'ORDER',
  USER = 'USER',
  PATIENT = 'PATIENT',
  PRESCRIBER = 'PRESCRIBER',
  INSURANCE = 'INSURANCE',
  ADDRESS = 'ADDRESS',
  HEALTH_PROFILE = 'HEALTH_PROFILE',
  ORDER_LIST = 'ORDER_LIST',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface NdcPackage {
  _id: string
  ndcProduct: NdcProduct
  ndc: string
  description: string
  raw: {
    NDCPACKAGECODE: string
  }
}

export interface NdcProduct {
  _id: string
  ndc: string
  productType: string
  classes: string[]
  dosages: string[]
  alternativeNames: string[]
  substance: string[]
  labeler: string
  name: string
  applicationNumber: string
  strengthUnits: string[]
  deaSchedule: string
  certificationExpiration: string
  ndcPackages: NdcPackage[]
}

export enum FillStatus {
  inFulfillment = 'InFulfillment',
  completed = 'Complete',
  cancelled = 'Cancelled',
}

export enum DURStatus {
  none = 'none',
  mild = 'mild',
  moderate = 'moderate',
  severe = 'severe',
}

export enum DURType {
  none = 'none',
  duplicateTherapy = 'duplicateTherapy',
  ddi = 'drugDrugInteraction',
  inferredCondition = 'inferredCondition',
  reportedCondition = 'reportedCondition',
  allergy = 'allergy',
  ageContraindication = 'ageContraindication',
  genderContraindication = 'genderContraindication',
  dosingIndications = 'dosingIndications',
  ingredientDosingIndications = 'ingredientDosingIndications',
}

export interface DURSubject {
  idField: string
  name: string
  type: string
  value: string
  fromHistory?: boolean
}

export interface DURSubjectInputs {
  allergies: DURSubject[]
  conditions: DURSubject[]
  medications: DURSubject[]
}

export interface DURRecord {
  input: DURSubjectInputs
  results: DUR[]
  updatedAt: Date
  createdAt: Date
}
export interface DUR {
  status: DURStatus
  type: DURType
  subjectTherapy?: Partial<Prescription>
  subjects: DURSubject[]
  management?: string
  directions?: string
}

export interface Prescriber extends PrescriberIdentification {
  _id: string
  firstName: string
  lastName: string
  addresses: Address[]
  contacts?: {
    phone: string
    fax?: string
    email?: string
  }
  primaryPhoneSequenceNumber: string
  prescriberTypeID?: string
  prescriberTypeText?: string
  classificationID: string
  classificationText: string
  primarySpecializationID: string
  primarySpecializationText: string
  isSupervisor: string
  comments: Comments
  marketerEmployeePioneerRxID: string
  changedOnUTCDateTime: Date
  createdAt?: Date
  npiDoc?: {
    gender?: string
    deactivationDate?: string
  }
}

export interface Comments {
  informational: string
  critical: string
  pointOfSale: string
}

export interface PrescriberIdentification {
  prescriberPioneerRxID: string
  npi: string
  dea: string
  dps: string
  stateLicense: string
  serialNumberPerson: string
}

export interface Medication {
  date: string
  daysSupply: number
  directions: string
  gcn?: string
  ndc: string
  prn?: string
  quantity: number
  upc?: string
}

export interface DispensedMedication extends Medication {
  strength: Strength
  name: string
  daysSupply: number
  /** This is a string of a number timestamp. */
  dispensedAt: string
  manufacturer: string
  quantityPerPackage?: number
  packagesRequired?: number
}

export interface Strength {
  value?: string
  form?: string
  unit?: string
}

declare type RxFillRequestPaymentType = 'cash' | 'insurance'

export type RejectionDetails = {
  reason: string
  reasonCode: RejectionReason
  message?: string
}

export interface RxFillRequestDur {
  orderInsuranceOptionId: string
  levelOfEffort?: string
  professionalService?: string
  reasonForService?: string
  resultOfService?: string
  coAgentQualifier?: string
  coAgentId?: string
}

export interface NumericOverrideCode {
  value: number
  applyTo: string
}

export interface NumericArrayOverrideCode {
  value: number[]
  applyTo: string
}

export interface StringOverrideCode {
  value: string
  applyTo: string
}

export interface RxFillRequestOverrideCodes {
  orderInsuranceOptionId: string
  priorAuthorizationTypeCode?: NumericOverrideCode
  levelOfService?: NumericOverrideCode
  productIdQualifier?: StringOverrideCode
  submissionClarification?: NumericArrayOverrideCode
  otherCoverageCode?: NumericOverrideCode
}

export interface RxFillRequestOverrides {
  dateOfService?: string
  ingredientCostSubmitted?: number
  dispensingFeeSubmitted?: number
  usualAndCustomaryCharge?: number
  grossAmountDue?: number
  basisOfCostDetermination?: string
}

export interface RXFillRequest {
  _id: string
  prescriptionId: string
  prescription: Prescription
  fillId?: string
  fill: Fill
  status: RXFillRequestStatus
  rejectionDetails?: RejectionDetails
  requestedMedicationName?: string
  quantity: number
  paymentType: RxFillRequestPaymentType
  coverMyMedsKey: string
  durs: RxFillRequestDur[]
  overrideCodes: RxFillRequestOverrideCodes[]
  overrides: RxFillRequestOverrides
  machine: MachineOptions
}

export interface ClaimsGroupClaim {
  prescriptionRefNumberQualifier: string
  prescriptionRefNumber: string
  productIdQualifier: string
  productId: string
  quantityDispensed: number
  fillNumber: number
  daysSupply: number
  compoundCode: number
  dawCode: number
  datePrescriptionWritten: Date
  numberOfRefillsAuthorized: number
  prescriptionOriginCode: string
  otherCoverageCode: string
  specialPackagingIndicator: string
  pharmacyServiceType: string
}

export interface ClaimsGroupPricing {
  ingredientCostSubmitted: number
  dispensingFeeSubmitted: number
  patientPayAmountSubmitted: number
  usualAndCustomaryCharge: number
  grossAmountDue: number
  basisOfCostDetermination: string
}

export interface ClaimsGroupPrescriber {
  prescriberIdQualifier: string
  prescriberId: string
  lastName: string
  state: string
}

export interface ClaimsGroup {
  claim: ClaimsGroupClaim
  pricing: ClaimsGroupPricing
  prescriber: ClaimsGroupPrescriber
}

export interface ClaimsSent extends TypeName {
  edi: string
  header: {
    bin: string
    version: string
    transactionCode: string
    processorControlNumber: string
    transactionCount: number
    serviceProviderIdQualifier: string
    serviceProviderId: string
    dateOfService: Date
    softwareVendorId: string
  }
  metadata: {
    insurance: {
      cardholderId: string
      eligibilityClarificationCode: string
      groupId: string
      personCode: string
      patientRelationshipCode: string
    }
    patient: {
      dateOfBirth: string
      genderCode: string
      firstName: string
      lastName: string
      streetAddress: string
      city: string
      state: string
      zip: string
      placeOfService: string
      pregnancyIndicator: string
      residence: string
    }
  }
  groups: ClaimsGroup[]
}

interface ClaimsReceivedGroupResponseRejectCode {
  rejectCode: string
  rejectCodeTranslated: string
}

interface TypeName {
  __typename: string
}

export interface ClaimsReceived extends TypeName {
  edi: string
  header: {
    bin: string
    version: string
    transactionCode: string
    processorControlNumber: string
    transactionCount: number
    serviceProviderIdQualifier: string
    serviceProviderId: string
    dateOfService: Date
    softwareVendorId: string
  }
  metadata: {
    responseMessage: {
      message: string
    }
    patientResponse: {
      dateOfBirth: Date
    }
    insuranceResponse: {
      groupId: string
      networkReimbustmentId: string
    }
  }
  groups: [
    {
      claimResponse: {
        prescriptionRefNumberQualifier: string
        prescriptionRefNumber: string
      }
      pricingResponse: {
        patientPayAmount: number
        ingredientCostPaid: number
        dispensingFeePaid: number
        totalAmountPaid: number
        accumulatedDeductibleAmount: number
        basisOfReimbusementDetermination: string
      }
      responseStatus: {
        statusText: string
      }
      response: {
        transactionStatus: string
        authorizationNumber: string
        rejectCodes: ClaimsReceivedGroupResponseRejectCode[]
        additionalMessageInformation: string
      }
    }
  ]
}

export interface Claims {
  _id?: string
  externalId?: string
  sent?: ClaimsSent
  received?: ClaimsReceived
  status?: string
  responseMessage?: string
  bin?: string
  pcn?: string
  cost?: number
  dateOfService?: string
  billingOrder?: number
  payerName?: string
  transmissionDate?: Date
  reversal?: {
    reversedAt: Date
    transaction: {
      sent: {
        edi: string
      }
      received: {
        edi: string
      }
    }
  }
  failedReversals?: []
}
export interface FillEdiPricing {
  ingredientCostSubmitted: number
  dispensingFeeSubmitted?: number
  patientPayAmountSubmitted?: number
  incentiveAmountSubmitted?: number
  otherAmountClaims?: {
    otherAmountClaimSubmittedQualifier: string
    otherAmountClaimSubmitted: number
  }[]
  usualAndCustomaryCharge?: number
  grossAmountDue: number
  basisOfCostDetermination?: string
  flatSalesTaxSubmitted?: number
}

export interface Verification {
  pharmacistId: string
  verifiedAt?: Date
}
export interface Verifications {
  pv1?: Verification
  pv2?: Verification
}
export interface Fill {
  _id: string
  createdAt: Date
  dispensed: DispensedMedication
  dispensedAt: Date
  durScreenings?: DURRecord[]
  fillNumber: number
  isPartialComplete?: boolean
  isPartialFill?: boolean
  lastPrintedAt?: Date
  labelsPrinted?: number
  location: Location
  locationId: string
  rxFillCode: string
  shortFillCode: string
  status: FillStatus
  triages: Triage[]
  updatedAt: Date
  order?: Order
  isPioneerHistory?: boolean
  claims: Claims[]
  isReplacement: boolean
  replacementFills: number
  ediPricing: FillEdiPricing
  verifications?: Verifications
}

export interface Triage {
  userPlacedInTriage: User
  userRemovedFromTriage: User
  reason: string
  inTriageUserMessage: string
  removeFromTriageUserMessage: string
  startDate: string
  endDate: string
}

export interface Vials {
  image: string
  number: number
}

export interface MachineOptions {
  deviceName: string
  error: string
  lastActivity: string
  rxId: string
  status: string
  updatedAt: Date
  vials: Vials[]
}

export enum InsuranceDesignationType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Coupon = 'Coupon',
  Tertiary = 'Tertiary',
}

export interface InsuranceDur {
  orderInsuranceOptionId: string
  reasonForService?: string
  professionalService?: string
  resultOfService?: string
  levelOfEffort?: string
}

export interface InsuranceImages {
  front: string
  back: string
}

export interface InsuranceValues {
  bin?: string
  pcn?: string
  group?: string
  cardholderId: string
  name?: string
  helpdeskNumber?: string
  personCode?: string
  relationship?: string
  designation?: InsuranceDesignationType
  overrideCodes?: RxFillRequestOverrideCodes
  durs?: InsuranceDur[]
  governmentSponsoredPlan?: boolean
  images?: InsuranceImages
}

export interface Insurance extends InsuranceValues {
  _id: string
}

export interface OrderInsurance extends Insurance {
  orderInsuranceOptionId: string
}

export enum PrescriptionStatus {
  'onHold' = 'OnHold',
  'inFulfillment' = 'InFulfillment',
  'expiredOrNoRefills' = 'ExpiredNoRefills',
  'transferred' = 'Transferred',
  'discontinued' = 'Discontinued',
  'filled' = 'Filled',
}

export interface Escript {
  _id: string
  ndc: string
  medicationPrescribed: EscriptMedicationPrescribed
  patient: EscriptPatient
  prescriber: EscriptPrescriber
  supervisor: EscriptSupervisor
  clinicName: string
  raw: string
  createdAt: Date
  customerId?: string
  inTriage?: boolean
  prescriberOrderNumber?: string
  prescriptionId?: string
  prescription?: Prescription
}

export interface EscriptPatient {
  firstName: string
  lastName: string
  dob: Date
  gender: string
  address: Address
  communicationNumbers: CommunicationNumbers
}

export interface EscriptMedicationPrescribed {
  drugDescription: string
  quantity: {
    value: number
  }
  sig: string
  diagnosis: {
    primary: {
      description: string
    }
  }
  writtenDate: Date
  substitutions: string
  daysSupply: number
  numberOfRefills: number
  note: string
}

export interface EscriptSupervisor {
  firstName: string
  lastName: string
}

export interface EscriptPrescriber {
  firstName: string
  lastName: string
  stateLicenseNumber: string
  npi: string
  dob: Date
  deaNumber: string
  gender: string
  address: Address
  communicationNumbers: CommunicationNumbers
}

interface CommunicationNumbers {
  phone: {
    number: string
  }
}

export interface TransferOut {
  transferredByUser: User
  date: string
  pharmacy: string
  address: Address
  phone: string
  fax: string
  dea: string
  receivingPharmacist: string
  faxInfo: TransferFaxInfo
  transferImage: Document
  fillId?: string
}

interface TransferFaxInfo {
  status: string
  sid: string
  error: string
  errorCode: string
}

export interface Icd10 {
  _id: string
  code: string
  text: string
}

export interface RefillRequest {
  rxRenewalRequestId: string
  status: RefillResponseStatus
  delivered?: boolean
  prescriptionId?: string
  responses?: [
    {
      rxRenewalResponseId?: string
      surescriptsStatusMessageId?: string
      surescriptsVerifyMessageId?: string
      surescriptsErrorMessageId?: string
      message: string
      receivedAt: Date
    }
  ]
  createdAt: Date
  updatedAt: Date
}

export enum RefillResponseStatus {
  Pending = 'Pending', // 'Pending' is internal status for vision, the remaining are NCPDP/Surescripts
  Error = 'Error', // 'Error' is internal status for vision
  Approved = 'Approved',
  ApprovedWithChanges = 'ApprovedWithChanges',
  Replace = 'Replace',
  DeniedNewPrescriptionToFollow = 'DeniedNewPrescriptionToFollow',
  Denied = 'Denied',
}

export interface Prescription {
  // TruepillOS
  // Unsure
  _id: string
  annotations?: Log[]
  logs: Log[]
  customer?: Customer
  customerId: string
  daw: number
  daysSupply: number
  deaSchedule: string
  description: string
  directions: string
  dosageForm: string
  escript: Escript
  externalId: string
  expirationDate: string
  effectiveDate?: string
  fills: Fill[]
  gcn?: string
  icd10?: string[]
  icd10s?: Icd10[]
  isPioneerOwned: boolean
  locationId: string
  ndc: string
  nextFillNumber: number
  note?: string
  numberOfRefills: number
  refillRequests?: [RefillRequest]
  origin: string
  patient: Patient
  patientId: Patient['_id']
  prescriber: Prescriber
  prescriberId: Prescriber['_id']
  prohibitRenewalRequest?: boolean
  followUpPrescriberId?: Prescriber['_id']
  followUpPrescriber?: Prescriber
  prn?: boolean
  quantity: SurescriptsNumber
  quantityRemaining: number
  refillsRemaining: number
  rxImage: Document
  rxImageId: string
  rxNumber: number
  status: PrescriptionStatus
  strength?: Strength
  totalQuantity: number
  transfers: TransferOut[]
  transferredOut?: boolean
  triplicate?: string
  upc?: string
  writtenDate: string
  name: string
  createdAt: Date
  isRefill: number
  medicationName: string
  medicationSig: string
  prescribedQuantity: string
  numberOfRefillsAllowed: number
  lastFilledDate: string
  filledDateUtc: string
  autofilledStatus: string
  prescriptionToken: string
  autofilledId: string
  location: string
}

export interface Document {
  _id: string
  s3: S3Document
  s3SignedUrl: string
  patient: Patient
  uploadedBy: UploadedBy
}

export interface S3Document {
  key: string
  bucket: string
}

export interface UploadedBy {
  user: User
  service: string
}

export enum PaymentType {
  cash = 'cash',
  insurance = 'insurance',
  split = 'split',
}

export interface Log {
  _id: string
  isAnnotation: boolean
  userId: string
  patient?: Patient
  patientId?: string
  prescriber?: Prescriber
  prescriberId?: string
  prescription?: Prescription
  prescriptionId?: string
  autobotId?: string
  parataNdcId?: string
  fill?: Fill
  fillId?: string
  order?: Order
  orderId?: string
  event: string
  change?: LogChange
  createdAt: string
  message: string
  tags: string[]
}

export interface LogChange {
  field?: string
  newValue: string
  oldValue: string
}

export interface Patient {
  _id: string
  gender: string
  logs: Log[]
  firstName: string
  lastName: string
  dob: string
  address?: {
    home?: Address
  }
  contacts?: {
    phone?: string
    email?: string
    sms?: string
  }
  createdAt: Date
  insurances?: Insurance[]
  medicalHistory?: DURSubjectInputs
  governmentSponsoredPlan: boolean
}

export interface PharmacyAddress extends Address {
  purpose: string
  fax: string
}

export interface Pharmacy {
  _id: string
  npi: string
  organizationName: string
  addresses: PharmacyAddress[]
  practiceAddresses: PharmacyAddress[]
  authorizedFirstName: string
  authorizedLastName: string
  authorizedMiddleName: string
  authorizedNamePrefix: string
  authorizedNameSuffix: string
  authorizedCredential: string
}

export enum RXFillRequestStatus {
  copayPending = 'CopayPending',
  pv1 = 'PV1',
  preAdjudication = 'PreAdjudication',
  adjudication = 'Adjudication',
  priorAuthorization = 'PriorAuthorization',
  fill = 'Fill',
  automation = 'Automation',
  pv2 = 'PV2',
  completed = 'Complete',
  cancelled = 'Cancelled',
}

export enum ShippingMethod {
  UspsPriority = 'usps_priority',
  UspsPriorityExpress = 'usps_priority_express',
  UspsFirst = 'usps_first',
  FedexPriorityOvernight = 'fedex_priority_overnight',
  FedexStandardOvernight = 'fedex_standard_overnight',
  FedexGround = 'fedex_ground',
  Fedex2Day = 'fedex_2_day',
  UpsSurepostLightweight = 'ups_surepost_lightweight',
  FedexPriorityExpress = 'fedex_priority_express',
  UpsNextDayAir = 'ups_next_day_air',
  UpsMailInnovationsDomestic = 'ups_mail_innovations_domestic',
  DhlEcommerceParcelPlusExpeditedMax = 'dhl_ecommerce_parcel_plus_expedited_max',
  UpsNextDayAirSaver = 'ups_next_day_air_saver',
  UpsSecondDayAir = 'ups_second_day_air',
  Ups3DaySelect = 'ups_3_day_select',
  UpsGround = 'ups_ground',
  UpsNextDayAirEarlyAm = 'ups_next_day_air_early_am',
  UpsSecondDayAirAm = 'ups_second_day_air_am',
  FedexSmartPost = 'fedex_smart_post',
  UspsPriorityMailExpress = 'usps_priority_mail_express',
}

export interface Error {
  error: string
}

export interface OptumProduct {
  slug: string
  sku: string
  metaDescription: string
  price: number
  brand: string
  image: string
  thumbnail: string
  name: string
  quantity: number
  [key: string]: string | number
}

export interface OptumOrderDetail {
  products: OptumProduct[]
  lineItems?: OrderLineItem[]
  callbackUrl: string
  cardNumber: string
  createdAt: Date
  discount: string
  shippingMethod: string
  shippingMethodName: string
  shippingPrice: number
  status: string
  taxesPrice: number
  totalPrice: number
  trackingUrl: string
  orderId: string
  wildcard: string
  client: string
}

export interface OptumOrderUser {
  userId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  urlToken: string
}

export interface OptumPayments {
  amount: number
  status: string
  statusDetail?: string
  signatureRequired: boolean
  promotionName?: string
  cardLast4Digits: string
  cardBrand: string
  cardExpirationDate: string
  lineItems: OrderLineItem[]
  taxes: number
  discount: number
  shipping: number
  id?: string
}

export interface OptumOrderInsurance {
  orderId: string
  lineItems: OptumInsuranceLineItem[]
  copayAmount: number
  totalInsurancePayment: number
}

export interface OptumOrder {
  address: Address
  order: OptumOrderDetail
  insurance?: OptumOrderInsurance
  user: OptumOrderUser
  payments: OrderPayment[]
}

export interface TPCSOrder {
  address: Address
  order: TPCSOrderDetail
  user: OrderUser
  payments: OrderPayment[]
}

export interface TPCSOrderDetail {
  insurance?: OptumOrderInsurance
  products: TPCSProduct[]
  lineItems: [OrderLineItem]
  createdAt: Date
  discount?: string
  shippingMethod?: string
  shippingMethodName?: string
  shippingPrice: number
  status?: string
  taxesPrice: number
  totalPrice: number
  trackingUrl?: string
  orderId: string
  promotionName?: string
  callbackUrl?: string
  payment?: Payment
}

export interface TPCSProduct {
  slug: string
  sku: string
  metaDescription: string
  price: number
  brand: string
  image: string
  thumbnail: string
  name: string
  quantity: number
  [key: string]: string | number
}

export interface OrderUser {
  userId: number
  firstName: string
  lastName: string
  email: string
  phone: string
  urlToken: string
}

export type Product = {
  slug?: string
  sku: string
  name: string
  quantity: number
  price: number
  paymentIntent?: string
  isFsaOrHsa: boolean
  taxAmount?: number
  discountAmount?: number
}

export interface OrderLineItem {
  id: string
  name: string
  isFsaOrHsa: boolean
  paymentId: string
  sku: string
  price: number
  isHsa: boolean
  isFsa: boolean
  quantity: number
  taxAmount: number
  discountAmount: number
  product: OTCProduct | NdcProduct | OptumProduct
  lineItemId: string
  copayCost: number
  insurancePayment: number
  bpe: number
  primaryInsurance: {
    claimStatus: string
    rejectionCode?: {
      code?: string
      message?: string
    }[]
  }
}

export interface OptumInsuranceLineItem {
  lineItemId: string
  copayCost: number
  insurancePayment: number
  bpe: number
  primaryInsurance: {
    claimStatus: string
    rejectionCode?: {
      code?: string
      message?: string
    }[]
  }
}

export type OrderPayment = {
  id?: string
  paymentIntent?: string
  amount: number
  status: string
  statusDetail?: string
  signatureRequired: boolean
  promotionName?: string
  cardLast4Digits: string
  cardBrand: string
  cardExpirationDate: string
  lineItems: [OrderLineItem]
  taxes: number
  discount: number
  shipping: number
  paymentIntentId?: string
}

export interface IPagination {
  currentPage: number
  from: number
  lastPage: number
  perPage: number
  to: number
  total: number
}

export type BreadCrumbs = { link: string; name: string }

export type ChipState = 'primary' | 'info' | 'warning' | 'error' | 'success' | 'disabled'

export interface Option<T = any> {
  label: string
  value: T
  icon?: FunctionComponent<{ fill: string }>
}
