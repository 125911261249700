import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef, ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from '@truepill/react-capsule'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'

interface HyperLinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  children: ReactNode
  externalIcon?: boolean
}

export const HyperLink = forwardRef<HTMLAnchorElement, HyperLinkProps>((props, ref) => {
  const { externalIcon = false, children, ...rest } = props

  return (
    <StyledHyperLink target="_blank" rel="noopener noreferrer" {...rest} ref={ref}>
      {children} {externalIcon && <ExternalLinkIcon size={16} />}
    </StyledHyperLink>
  )
})

const StyledHyperLink = styled.a`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${theme.colors['primary-500'].value};
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-left: 0.25rem;
  }
`
