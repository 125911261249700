import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Card, Grid, GridItem, Header, theme } from '@truepill/react-capsule'

import { Fab } from './Buttons'

type Props = {
  visible?: boolean
  title?: string
  firstLoad?: boolean
  onClose?: VoidFunction
  children?: ReactNode
}

export const Pane: FC<Props> = props => {
  const { children, title, onClose, visible = false, ...other } = props

  return (
    <StylePane data-visible={visible} {...other}>
      <PaneContent>
        <Grid>
          <GridItem desktop={12} tablet={8}>
            <Grid>
              <GridItem desktop={11} tablet={7} css={{ display: 'flex', alignItems: 'center' }}>
                {title && <Header variant="4xl">{title}</Header>}
              </GridItem>
              <GridItem desktop={1} tablet={1}>
                <Fab icon="X" size={'xs'} onClick={onClose} />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem desktop={12} tablet={8}>
            {children}
          </GridItem>
        </Grid>
      </PaneContent>
    </StylePane>
  )
}

const PaneContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const PaneContentWithScroll = styled.div`
  overflow: auto;
  height: calc(100vh - 15.3rem);
`

const StylePane = styled(Card)<Omit<Props, 'title'>>`
  position: fixed;
  top: ${theme.space['4xl'].value};
  height: calc(100vh - ${theme.space['5xl'].value});
  right: -30rem;
  width: 30rem;
  z-index: 200;
  border-radius: ${theme.radii.md.value};
  box-shadow: ${theme.shadows.lg.value};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  animation-fill-mode: forwards;
  overflow: hidden;
  transition: 200ms;

  &[data-visible='true'] {
    right: 1rem;

    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: ${theme.colors.white.value};
      opacity: 0.6;
      z-index: -1;
    }
  }
`
