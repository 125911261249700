import { CapsuleThemeOverrides, theme } from '@truepill/react-capsule'

export const capsuleOverrides: CapsuleThemeOverrides = {
  chip: {
    fontSize: '11px',
    textTransform: 'uppercase',
    fontWeight: 700,
    width: 'fit-content',
  },

  modalOverlay: {
    zIndex: 99,
  },
  grid: {
    /* width */
    '::-webkit-scrollbar': {
      width: theme.space.xs.value,
    },

    /* Track */
    '::-webkit-scrollbar-track': {
      boxShadow: theme.shadows.xl.value,
      borderRadius: theme.radii.md.value,
    },

    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: theme.colors['gray-300'].value,
      borderRadius: theme.radii.md.value,
    },

    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      background: theme.colors['gray-500'].value,
    },
  },
}
