import { FC, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { theme } from '@truepill/react-capsule'

type Props = {
  visible: boolean
  paddingTop: boolean
  children: ReactNode
}

export const SideToolBar: FC<Props> = props => {
  const { visible = true, children, paddingTop } = props

  return (
    <SideBarToolsContainer visible={visible} paddingTop={paddingTop}>
      <SideBarToolsWrapper visible={visible}>{children}</SideBarToolsWrapper>
    </SideBarToolsContainer>
  )
}

const SideBarToolsWrapper = styled.div<{
  visible?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  > button:not(:first-child) {
    margin-top: 0.3125rem;
  }
  > button {
    margin-bottom: 0.3125rem;
  }
  ${({ visible }) => (visible ? slideInAnimation : slideOutAnimation)};
  animation-fill-mode: forwards;
`
const SideBarToolsContainer = styled.div<{ visible: boolean; paddingTop?: boolean }>`
  position: sticky;
  ${({ paddingTop }) => (paddingTop ? `top: ${theme.space['7xl'].value}` : `top: ${theme.space['8xl'].value}`)};
  display: flex;
  ${({ visible }) => !visible && `overflow-x: hidden;`};
  z-index: 1;
`

const slideIn = keyframes`
  0% {
    white-space: nowrap;
    padding-left: 10rem;
  }
  100% {
    flex: 1;
    padding-left: 0;
  }
`

const slideOut = keyframes`
  0% {
    white-space: nowrap;
    padding-left: 0;
  }
  100% {
    white-space: nowrap;
    padding-left: 10rem;
  }
`

const slideInAnimation = css`
  animation: ${slideIn} 300ms;
`

const slideOutAnimation = css`
  animation: ${slideOut} 600ms;
`
