import { FC } from 'react'
import styled from 'styled-components'
import { LoadingSpinner, Text, theme } from '@truepill/react-capsule'

interface IGridOverlaysProps {
  loading?: boolean
  totalRowCount: number
  isFechingCompleted?: boolean
  content?: string
}

export const GridOverlays: FC<IGridOverlaysProps> = ({ loading, totalRowCount, isFechingCompleted, content }) => {
  const showNoRowsOverlay = !loading && totalRowCount === 0 && isFechingCompleted

  if (showNoRowsOverlay) {
    return (
      <Wrapper data-loading={loading && totalRowCount}>
        <GridOverlay>
          <Text css={{ color: theme.colors['typography-medium'].value }} variant="overline">
            {content || 'No Data'}
          </Text>
        </GridOverlay>
      </Wrapper>
    )
  }

  if (loading) {
    return (
      <Wrapper data-loading={loading && totalRowCount}>
        <GridOverlay>
          <LoadingSpinner />
        </GridOverlay>
      </Wrapper>
    )
  }

  return null
}

const Wrapper = styled.tr`
  min-height: 300px;
  width: 100%;
  display: block;
  align-items: center;
  position: relative;
  top: 0;
  bottom: 0;

  &[data-loading='true'] {
    position: absolute;
  }
`

const GridOverlay = styled.th`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-color: rgba(255, 255, 255, 0.5);
`
