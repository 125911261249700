import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useReducer } from 'react'

import { useCustomerContext } from './CustomerProvider'
import { NotesPanel } from '../components'
import type { NoteFragment } from '../generated/graphql'
import { useGetNotesLazyQuery } from '../generated/graphql'

import type { NoteResourceEnum } from 'types'

export interface NotesType {
  resource_id: string
  resource_type: keyof typeof NoteResourceEnum
}
export interface NotesProviderProps extends NotesType {
  count: number
  data: NoteFragment[]
  loadNotes: (noteType: NotesType) => void
}

export const NotesContext = createContext<NotesProviderProps>({
  data: [],
  count: 0,
  loadNotes: () => undefined,
  resource_id: '',
  resource_type: 'USER',
})

interface NotesReducerState {
  data: NoteFragment[]
  count: number
  resource_id: string
  resource_type: keyof typeof NoteResourceEnum
}

type NotesAction = {
  type: 'GET_NOTES_DATA' | 'UPDATE_RESOURCE'
  data: NoteFragment[]
  count?: number
  resource_id?: string
  resource_type?: keyof typeof NoteResourceEnum
}

export const useNotesContext = () => useContext(NotesContext)

const NotesReducer = (state: NotesReducerState, action: NotesAction) => {
  const { type, data = [], count = 0 } = action
  switch (type) {
    case 'GET_NOTES_DATA':
      return { ...state, count, data }
    case 'UPDATE_RESOURCE':
      return { ...state, ...action }
    default:
      return state
  }
}

type Props = {
  children: ReactNode
}

export const NotesProvider: FC<Props> = props => {
  const { saveCurrentCustomer } = useCustomerContext()

  const [{ data, count, resource_id, resource_type }, dispatch] = useReducer(NotesReducer, {
    data: [],
    count: 0,
    resource_id: '',
    resource_type: 'USER',
  })
  const [getNotes, { data: notes, loading }] = useGetNotesLazyQuery()
  useEffect(() => {
    if (!loading) {
      dispatch({ type: 'GET_NOTES_DATA', data: notes?.getNotes as NoteFragment[], count: notes?.getNotes?.length })
    }
  }, [dispatch, notes, loading])

  const loadNotes = useCallback(
    (noteType: NotesType) => {
      dispatch({ type: 'UPDATE_RESOURCE', data, ...noteType })
      getNotes({ variables: { ...noteType, client: saveCurrentCustomer.customerSlug } })
    },
    [saveCurrentCustomer.customerSlug, data, getNotes]
  )
  const value: NotesProviderProps = {
    data,
    count,
    resource_id,
    resource_type,
    loadNotes,
  }

  return (
    <NotesContext.Provider value={value} {...props}>
      {props.children}
      <NotesPanel />
    </NotesContext.Provider>
  )
}
