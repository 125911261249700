import React, { FC, Suspense } from 'react'
import styled from 'styled-components'
import { LoadingSpinner } from '@truepill/react-capsule'

const StyledRoot = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  minHeight: '100%',
  padding: 24,
})

const Fallback: FC = () => {
  return (
    <StyledRoot>
      <LoadingSpinner />
    </StyledRoot>
  )
}

export const Loadable = (Component: FC) => (props: any) => {
  return (
    <Suspense fallback={<Fallback />}>
      <Component {...props} />
    </Suspense>
  )
}
