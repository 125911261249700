import { ReactNode, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

interface DropDownProps {
  anchorToRight?: boolean
  children: ReactNode
  minWidth?: number
  onClickOutside?: () => void
  forceDisplay?: boolean
  preventScrolling?: boolean
  containerWidth?: number
  'data-testid'?: string
  heightOffset?: number
}

export const DropDownContainer = (props: DropDownProps) => {
  const {
    children,
    containerWidth,
    onClickOutside,
    heightOffset = 0,
    minWidth = 100,
    forceDisplay = false,
    anchorToRight = false,
    preventScrolling = true,
    'data-testid': testId = undefined,
  } = props

  const ref = useRef<HTMLDivElement>(null)

  useClickAway(ref, () => {
    if (onClickOutside) {
      onClickOutside()
    }
  })

  const [display, setDisplay] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const parent = ref.current.parentElement
      if (!parent) {
        return
      }

      const container = document.getElementById('MainContainer')
      if (container && preventScrolling) {
        container.style.overflow = 'hidden'
      }

      const parentRect = parent.getBoundingClientRect() as DOMRect

      const vWidth = document.documentElement.clientWidth

      let rightAnchored = anchorToRight || false

      // the dropdown shouldn't allow itself to extend beyond the window width
      let maxWidthVal = vWidth - parentRect.x

      // If maxWidth is less than the minimum width declared, anchor to the
      // right of the parent instead of theleft
      if (maxWidthVal < minWidth) {
        maxWidthVal = minWidth
        rightAnchored = true
      }

      // We add an extra 10px so we are clear of the edge of the page +
      // scrollbars.
      maxWidthVal -= 10

      const { top, left, right, maxHeight, maxWidth } = getCSSPositionOffsets(
        preventScrolling,
        rightAnchored,
        parentRect,
        maxWidthVal,
        heightOffset
      )

      ref.current.style.left = left
      ref.current.style.right = right
      ref.current.style.top = top
      ref.current.style.maxHeight = maxHeight
      ref.current.style.maxWidth = maxWidth

      setDisplay(true)
    }

    return () => {
      const container = document.getElementById('MainContainer')
      if (container && preventScrolling) {
        container.style.overflow = 'auto'
      }
    }
  }, [anchorToRight, heightOffset, minWidth, preventScrolling])

  return (
    <StyledDropDown
      data-testid={testId || 'dropdown'}
      display={forceDisplay || display ? 'flex' : 'none'}
      position={preventScrolling ? 'fixed' : 'absolute'}
      containerWidth={containerWidth}
      ref={ref}
    >
      <ul>{children}</ul>
    </StyledDropDown>
  )
}

const getCSSPositionOffsets = (
  preventScrolling: boolean,
  anchorToRight: boolean,
  parentRect: DOMRect,
  maxWidth: number,
  heightOffset: number
): { left: string; right: string; top: string; maxHeight: string; maxWidth: string } => {
  if (preventScrolling) {
    const left = anchorToRight ? `${parentRect.x + parentRect.width + maxWidth + 10}px` : `${parentRect?.x}px`

    return {
      top: `${parentRect.y + parentRect.height}px`,
      left,
      right: 'auto',
      maxHeight: `calc(100vh - ${parentRect.top + heightOffset}px)`,
      maxWidth: `${maxWidth}px`,
    }
  } else {
    return {
      top: '100%',
      left: anchorToRight ? 'auto' : '0px',
      right: anchorToRight ? '0px' : 'auto',
      maxHeight: '60vh',
      maxWidth: `${maxWidth}px`,
    }
  }
}

const StyledDropDown = styled.div<{
  display: string
  position: string
  containerWidth?: number
}>`
  position: ${({ position }) => position};
  background-color: white;
  overflow-y: auto;
  border-radius: 0.25rem;
  z-index: 100;
  outline: none;
  display: ${({ display }) => display};
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-width: ${({ containerWidth }) => `${containerWidth}px`};
`
