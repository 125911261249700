import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '@truepill/react-capsule'

import { Navigation } from './components/Navigation'
import { Modal } from 'components/Modal'
import { NAV_MENU_BREAKPOINT } from '../styles/styleVariables'

export const LayoutMain: FC = () => {
  return (
    <>
      <Modal />
      <Navigation />
      <MainContainer id="MainContainer">
        <Outlet />
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  height: calc(100vh - 3.75rem);
  overflow-y: auto;
  overflow-x: hidden;

  padding: ${theme.space.md.value} ${theme.space.md.value} 0;

  @media screen and (min-width: ${NAV_MENU_BREAKPOINT}) {
    padding: ${theme.space.md.value} ${theme.space.xl.value} 0;
  }
`
