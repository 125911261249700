import { ButtonHTMLAttributes, ComponentProps, DetailedHTMLProps, FC, ReactNode, RefAttributes } from 'react'
import { Button, Spacer } from '@truepill/react-capsule'
import styled from 'styled-components'
import { Icon } from 'react-feather'

import { CircularProgress } from '../CircularProgress'

interface IButtonProps {
  loading?: boolean
  loadingIndicator?: ReactNode
  icon?: Icon
  fullWidth?: boolean
  iconPosition?: 'left' | 'right'
}

const LoadingIndicator = <CircularProgress size={16} />

type ComponentType = FC<
  IButtonProps &
    Pick<
      DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
      'key' | keyof ButtonHTMLAttributes<HTMLButtonElement>
    > &
    RefAttributes<HTMLButtonElement> &
    ComponentProps<typeof Button>
>

export const TpButton: ComponentType = props => {
  const {
    children,
    icon,
    iconPosition = 'left',
    loading = false,
    disabled = false,
    loadingIndicator = LoadingIndicator,
    css,
    onClick,
    size,
    fullWidth,
    ...otherProps
  } = props
  const IconComponent = icon as Icon

  return (
    <StyledButton
      size={'sm'}
      disabled={disabled || loading}
      {...otherProps}
      onClick={e => {
        e.preventDefault()
        onClick && onClick(e)
      }}
      css={{ ...css, width: fullWidth ? '100%' : 'auto', height: size === 'lg' ? '56px' : '48px' }}
    >
      {(loading || (icon && iconPosition === 'left')) && (
        <>
          <StyledWrapper>{loading ? loadingIndicator : <IconComponent size={16} strokeWidth={2} />}</StyledWrapper>
          <Spacer size="xs" />
        </>
      )}
      {children}
      {icon && iconPosition === 'right' && (
        <>
          <Spacer size="xs" />
          <StyledWrapper>{<IconComponent size={16} strokeWidth={2} />}</StyledWrapper>
        </>
      )}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledWrapper = styled.span`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
