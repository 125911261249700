import { format, isValid, parseISO } from 'date-fns'

type FormatTime = Date | string | number

export function formatDate(date: FormatTime): string {
  const dateValue: FormatTime = date.toString().length === 10 ? `${date.toString()}` : date

  if (typeof dateValue === 'string') {
    const parsedDate = parseISO(dateValue)

    if (isValid(parsedDate)) {
      return format(parsedDate, 'MM/dd/yyyy')
    } else if (dateValue.length === 8) {
      // '20001131' => '11/31/2000' - hack for legacy date
      const year = dateValue.substring(0, 4)
      const month = dateValue.substring(4, 6)
      const day = dateValue.substring(6, 8)

      return `${month}/${day}/${year}`
    }
  }

  return format(new Date(dateValue), 'MM/dd/yyyy')
}

export function formatMonthYear(date: FormatTime): string {
  return format(new Date(date), 'MM/yy')
}

export function formatLocalizedDate(date: FormatTime): string {
  return format(new Date(date), 'MMM d, yyyy')
}

export function formatLocalizedDateTime(date: FormatTime): string {
  return format(new Date(date), 'MMM d, yyyy h:mm a')
}

export function formatDateTime(date: FormatTime): string {
  return format(new Date(date), 'MM/dd/yyyy h:mm a')
}

/**
 * It takes a string that represents a date in milliseconds and returns a string that represents the date in a human-readable format
 * @param {string} timeStamp - string - The timestamp string to format ("1652438325991")
 * @returns A string ("05/13/2022 1:38 PM")
 */
export function formatStringTimeStamp(timeStamp: string): string {
  return format(new Date(parseInt(timeStamp, 10)), 'MM/dd/yyyy hh:mm a')
}
