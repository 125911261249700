import { ComponentProps, FC } from 'react'
import styled from 'styled-components'
import { theme, Tooltip } from '@truepill/react-capsule'
import type { IconProps } from 'react-feather'
import * as Icons from 'react-feather'

interface FabButtonProps extends IconProps {
  icon?: keyof typeof Icons
  toolTipProps?: ComponentProps<typeof Tooltip>
}

export const Icon: FC<FabButtonProps> = ({ icon, toolTipProps, children, ...iconProps }) => {
  // eslint-disable-next-line import/namespace
  const Icon = Icons[icon || 'Activity']
  return (
    <Tooltip variant="brand-light" {...toolTipProps}>
      <IconWrapper size={iconProps?.size}>
        {children || <Icon size={'1rem'} stroke={theme.colors['primary-900'].value} {...iconProps} />}
      </IconWrapper>
    </Tooltip>
  )
}

const IconWrapper = styled.span<{ size?: string | number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size || '1rem'};
  height: ${({ size }) => size || '1rem'}; ;
`
