import React, { FC, ReactNode } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { LoadingScreen } from '../components'
import { useMergeUser } from '../hooks'
import { Navigate } from 'react-router-dom'
import { RoutePath } from '../routes/paths'

interface Props {
  children: ReactNode
}

export const GuestGuard: FC<Props> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth0()

  const { loading } = useMergeUser()

  if (isLoading || loading) {
    return <LoadingScreen />
  }

  if (isAuthenticated) {
    return <Navigate to={RoutePath.Search} />
  }

  return <>{children}</>
}
