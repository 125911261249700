import { useContext } from 'react'

import { CustomerContext } from './CustomerProvider'
import type { CustomerProviderProps } from './types'

export const useCustomerContext = (): CustomerProviderProps => {
  const context = useContext(CustomerContext)

  if (!context) {
    throw new Error('Customer context must be use inside CustomerProvider')
  }

  return context
}
