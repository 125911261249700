import styled from 'styled-components'
import { Header, LoadingSpinner, theme } from '@truepill/react-capsule'

const NoResultWrapper = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NoResultMessage = ({ loading, message }: { loading?: boolean; message?: string }) => {
  return (
    <NoResultWrapper>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Header variant="6xl" as={'h3'} css={{ color: theme.colors['typography-light'] }}>
          {message || 'No results'}
        </Header>
      )}
    </NoResultWrapper>
  )
}
