import { FC, memo } from 'react'
import styled from 'styled-components'

import { PermissionBasedGuard } from '../../../guards'
import { Permissions } from '../../../constants'
import { config } from '../../../config'
import { NavigationLink } from './NavigationLink'
import { RoutePath } from '../../../routes/paths'

const navigationLinks = [
  {
    title: 'TPCS',
    path: RoutePath.Search,
    permissions: [Permissions.tpcsUser],
  },
  {
    title: 'Team',
    path: RoutePath.SearchTeam,
    permissions: [Permissions.listUsers, Permissions.tpcsUser],
  },
  {
    title: 'Fulfillment',
    path: `${config.tposUrl}/fulfillment`,
    permissions: [Permissions.tpcsUser],
    hotKey: '!',
    external: true,
  },
  {
    title: 'People',
    path: `${config.tposUrl}/people`,
    permissions: [Permissions.tpcsUser],
    hotKey: '@',
    external: true,
  },
  {
    title: 'Pharmacy',
    path: `${config.tposUrl}/pharmacy`,
    permissions: [Permissions.tpcsUser],
    hotKey: '#',
    external: true,
  },
  {
    title: 'Admin',
    path: `${config.tposUrl}/admin`,
    permissions: [Permissions.tpcsUser],
    hotKey: '$',
    external: true,
  },
]

export const NavButtonList: FC = memo(() => {
  return (
    <StyledNavButtonList>
      {navigationLinks.map(item => (
        <PermissionBasedGuard key={item.title} requiredPermissions={item.permissions}>
          <NavigationLink title={item.title} path={item.path} external={item.external} />
        </PermissionBasedGuard>
      ))}
    </StyledNavButtonList>
  )
})

const StyledNavButtonList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  gap: 1rem;
`
