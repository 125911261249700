import React, { FC, ReactNode, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Navigate, useLocation } from 'react-router-dom'

import Login from '../pages/authCallback'
import { LoadingScreen } from '../components'

interface Props {
  children: ReactNode
}

/**
 * If the user is not authenticated, show the login screen.
 * If the user is authenticated, show the requested page
 */
export const AuthGuard: FC<Props> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0()

  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)

    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}
