import Astellas from 'assets/clientsLogo/Astellas.svg'
import Calibrate from 'assets/clientsLogo/Calibrate.svg'
import CircleMedical from 'assets/clientsLogo/CircleMedical.svg'
import MarkCuban from 'assets/clientsLogo/MarkCuban.svg'
import Optum from 'assets/clientsLogo/Optum.svg'
import SouthernScripts from 'assets/clientsLogo/SouthernScripts.svg'

export const clientsLogos: Record<string, string> = {
  astellas: Astellas,
  calibrate: Calibrate,
  circle_medical: CircleMedical,
  mark_cuban: MarkCuban,
  optum: Optum,
  southern_scripts: SouthernScripts,
  southern_scripts_lc: SouthernScripts,
}
