interface QueryObject {
  [key: string]: any
}

const buildObjectFromString = (stringParam: string): any => {
  const keyValue = stringParam.split('=')
  const newParam: QueryObject = {}
  if (keyValue.length < 2) return newParam
  newParam[keyValue[0].trim()] = decodeURIComponent(keyValue[1].trim())
  return newParam
}

export const getObjFromQueryString = (queryString: string): any => {
  try {
    // This method does not support query string array params. To be enhanced in the future.
    if (!queryString) {
      return null
    }
    if (queryString[0] === '?') {
      queryString = queryString.substring(1)
    }
    const params = queryString.split('&')
    return params.reduce((obj: any, element, i) => {
      if (i === 1) {
        obj = buildObjectFromString(obj)
      }
      return { ...obj, ...buildObjectFromString(element) }
    })
  } catch (error) {
    throw new Error(`Something happened while trying to get an object from queryString.`)
  }
}
