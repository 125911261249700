import { FC, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingSpinner } from '@truepill/react-capsule'

import { Page } from '../components'

const AuthCallBack: FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const loginUser = useCallback(async (): Promise<void> => {
    try {
      await loginWithRedirect()
    } catch (error) {
      console.error(error)
    }
  }, [loginWithRedirect])

  useEffect(() => {
    if (!isAuthenticated) {
      loginUser()
    }
  }, [isAuthenticated, loginUser])

  return (
    <Page title="Auth">
      <StyledContent>
        <LoadingSpinner />
      </StyledContent>
    </Page>
  )
}

export default AuthCallBack

export const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 124px);
`
