export function getSessionStorageCache<T>(key: string): T | null {
  const json = window.sessionStorage.getItem(key)

  if (!json) {
    return null
  }

  try {
    return JSON.parse(json) as T
  } catch (e) {
    alert(`Error parsing ${key} from session storage`)
    return null
  }
}
