import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'

export function useAuth() {
  const { logout } = useAuth0()

  const handleLogout = useCallback(async () => {
    try {
      await logout({ returnTo: window.location.origin })
      localStorage.clear()
      sessionStorage.clear()
    } catch (error) {
      console.error(error)
    }
  }, [logout])

  return {
    handleLogout,
  }
}
