export const primaryColor = '#44bdd0'
export const primaryColorLight = '#e3f5f8'
export const primaryColorDark = '#44bcdf'
export const bodyPrimaryColor = '#242424'
export const bodySecondaryColor = '#aaaaaa'
export const contrastColor = '#212d41'
export const borderColor = '#cccccc'
export const accentPink = '#d82184'
export const primaryActiveButtonBackground = 'rgba(63, 190, 210, 1.00)'
export const primaryBackgroundColor = '#FFFFFF'
export const offsetBackgroundColor = '#efefef'
export const contrastBackgroundColor = '#F4F4F4'
export const contrastIconButtonColor = '#C4C4C4'
export const greyPaging = '#989898'
export const subduedColor = '#afbcc6'
export const grey = '#999999'
export const alertRed = '#da3d3d'
export const errorRed = '#DE3702'
export const errorLight = '#F8D7CC'
export const warningYellow = '#FFB803'
export const confirmColor = '#11CAB4'
export const confirmLight = '#CFF4F0'
export const highlightPrimaryColor = '#8FD7E3'
export const highlightPrimaryColorTransparent = 'rgba(143, 215, 227, 0.80)'
export const borderColorTransparent = 'rgba(255, 255, 255, 0.50)'
export const darkBlue = '#194D83'
export const primaryLight = '#44BDD0'
export const darkGray = '#999999'

export const NAV_MENU_BREAKPOINT = '1050px'

// e.g. @media screen (min-width: ${media.lg})
export const media = {
  sm: '576px',
  md: '768px',
  lg: '992px', // if nav menu changes names, additional items this might need to be updated
  xl: '1200px',
  pageWidth: '1450px',
}

export const color = {
  primary: primaryColor,
  primaryLight: primaryColorLight,
  bodyPrimary: bodyPrimaryColor,
  bodySecondary: bodySecondaryColor,
  contrast: contrastColor,
  border: borderColor,
  background: primaryBackgroundColor,
  offsetBackground: offsetBackgroundColor,
  subdued: subduedColor,
}

export const text = {
  primaryColor: primaryColor,
}

export const size = {
  pageWidth: 1450,
}
