import 'typeface-roboto'
import '@reach/tooltip/styles.css'
import '@reach/dialog/styles.css'

import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import LogRocket from 'logrocket'
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import setupLogRocketReact from 'logrocket-react'
import { BrowserRouter } from 'react-router-dom'

import { App } from './App'
import { SanitizedPHIKeys } from './types'
import { QueryParamProvider } from './libs/query-params'
import { ApolloProviderWithAuth0 } from './libs/apollo'
import { AuthProvider } from './libs/auth0'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <QueryParamProvider>
        <AuthProvider>
          <ApolloProviderWithAuth0>
            <StrictMode>
              <App />
            </StrictMode>
          </ApolloProviderWithAuth0>
        </AuthProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
)

if (process.env.NODE_ENV === 'production') {
  const { requestSanitizer, responseSanitizer } = LogRocketFuzzySanitizer.setup([...SanitizedPHIKeys])
  LogRocket.init('9iqk3t/tpos', {
    network: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestSanitizer,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseSanitizer,
    },
  })
  setupLogRocketReact(LogRocket)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
// Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
