import { ReactNode } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'

export const Form = <T,>({ children, ...method }: UseFormReturn<T> & { children: ReactNode }) => {
  return (
    <FormProvider {...method}>
      <form>{children}</form>
    </FormProvider>
  )
}
