export enum TpcsRole {
  Admin = 'tpcs:admin',
  OptumCustomerLeadSupportR = 'tpcs-optum-cslead-r',
  OptumCustomerLeadSupportWR = 'tpcs-optum-cslead-wr',
  OptumCustomerSupportR = 'tpcs-optum-csr-r',
  OptumCustomerSupportWR = 'tpcs-optum-csr-wr',
  MarkCubanLeadCustomerSupportR = 'tpcs-markcuban-cslead-r',
  MarkCubanLeadCustomerSupportWR = 'tpcs-markcuban-cslead-wr',
  MarkCubanCustomerSupportR = 'tpcs-markcuban-csr-r',
  MarkCubanCustomerSupportWR = 'tpcs-markcuban-csr-wr',
}

export const enum Permissions {
  tpcsUser = 'tpcs:user',
  listUsers = 'tpcs:list:members',
  assignAdminPermission = 'tpcs:truepill:assign:admin',
}
