import { ComponentProps, FC, useMemo } from 'react'
import styled from 'styled-components'
import { Breadcrumbs as BreadCrumbsCapsule, Text, theme } from '@truepill/react-capsule'

import { Link } from './Link'
import { BreadCrumbs as BreadCrumbsType } from 'types'
import { useLocation } from 'react-router-dom'

interface BreadCrumbsProps extends ComponentProps<typeof BreadCrumbsCapsule> {
  crumbs: BreadCrumbsType[]
}

export const BreadCrumbs: FC<BreadCrumbsProps> = ({ crumbs, ...props }) => {
  const { search } = useLocation()
  const renderCrumbs = useMemo(
    () =>
      crumbs.map((item, idx) => {
        if (item.link) {
          return <Link key={idx} title={item.name} to={`${item.link}${search}`} />
        }
        return <Text key={idx}>{item.name}</Text>
      }),
    [crumbs, search]
  )
  return <StyledBreadcrumbs {...props}>{renderCrumbs}</StyledBreadcrumbs>
}

const StyledBreadcrumbs = styled(BreadCrumbsCapsule)`
  li {
    a:-webkit-any-link {
      color: ${theme.colors['primary-700'].value};
      text-decoration-color: ${theme.colors['primary-700'].value};
    }
  }

  li:last-of-type {
    color: ${theme.colors['typography-medium'].value};
    text-decoration: none;
    cursor: default;
  }
`
