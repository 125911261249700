import { ComponentProps, FC } from 'react'
import styled from 'styled-components'
import { Button, theme, Tooltip } from '@truepill/react-capsule'
import type { IconProps } from 'react-feather'
import * as Icons from 'react-feather'
import { Icon } from '../Icon'

interface FabButtonProps extends ComponentProps<typeof Button> {
  icon?: keyof typeof Icons
  toolTipProps?: ComponentProps<typeof Tooltip>
  iconProps?: IconProps
  round?: boolean
  badge?: number
}

export const Fab: FC<FabButtonProps> = ({
  icon,
  toolTipProps,
  round = true,
  badge,
  children,
  iconProps,
  ...buttonProps
}) => {
  const badgeConvert = badge ? (badge > 99 ? '99+' : badge) : 0
  return (
    <Tooltip variant="brand-light" {...toolTipProps}>
      <StyledFab size="sm" variant={'primary-outline'} {...buttonProps} round={+round}>
        {!!badgeConvert && <Badge>{badgeConvert}</Badge>}
        <Icon icon={icon} {...iconProps}>
          {children}
        </Icon>
      </StyledFab>
    </Tooltip>
  )
}
export const StyledFab = styled(Button)<{ round: number }>`
  border-color: transparent;
  border-radius: ${theme.radii.xs.value};
  padding: ${theme.space['4xs'].value} ${theme.space.xs.value};
  &:not(:disabled) {
    &:hover {
      svg {
        stroke: ${theme.colors.white.value};
      }
    }
  }

  ${({ round, size }) =>
    round
      ? `
      padding: ${theme.space[size as keyof Pick<typeof theme.space, 'md' | '2xs' | 'xs' | 'sm' | 'lg'>].value};
      border-radius: ${theme.radii.xl.value};
    `
      : `border: none;
         display: flex;`}
`

const Badge = styled.span`
  position: absolute;
  height: 1.2rem;
  width: 1.2rem;
  top: -7px;
  right: -7px;
  z-index: 1;
  font-family: ${theme.fonts.base.value};
  font-size: ${theme.fontSizes.xs.value};
  color: ${theme.colors.white.value};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors['functional-error-dark'].value};
  border-radius: ${theme.radii.xl.value};
`
