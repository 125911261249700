export const config = {
  // Required for navigation links
  tposUrl: window.location.host.includes('.com')
    ? `${window.location.protocol}//${window.location.host.replace('tpcs', 'vision')}`
    : 'https://vision.falsepill.com',
}

export const AUTH0_API = {
  clientId: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_CLIENT_ID || '',
  domain: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_DOMAIN || '',
  audience: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_AUDIENCE || '',
  redirectUrl: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_REDIRECT_URL || '',
}

export const FULL_URL_VAR = window.__RUNTIME_CONFIG__.REACT_APP_LOCAL
export const PARTIAL_URL_VAR = window.__RUNTIME_CONFIG__.REACT_APP_BACKEND // If modifying this, make sure to update package.json to match

export const TP_DASH_URI = window.__RUNTIME_CONFIG__.REACT_APP_TP_DASH_URI

export const LS_CURRENT_CUSTOMER = 'currentCustomer'
