import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Grid, GridItem, Header, LoadingSpinner, Spacer, Text, theme } from '@truepill/react-capsule'

export type Rows = {
  title: string
  content?: JSX.Element | string
  hide?: boolean | undefined
}

type Props = {
  rows?: Rows[]
  isLoading?: boolean
  title?: string
  children?: ReactNode
}

export const DataBlock: FC<Props> = ({ isLoading = false, rows = [], title, children }) => {
  return (
    <>
      <Header bold variant="xl">
        {title}
      </Header>
      <Spacer size="xs" />
      {isLoading ? (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ) : (
        children || (
          <StyledBody>
            {rows.flatMap(({ title, content, hide }) =>
              hide ?
                [] :
                [
                  <Grid key={title}>
                    <GridItem desktop={4} tablet={3}>
                      <Text bold>{title}</Text>
                    </GridItem>
                    <GridItem desktop={8} tablet={5}>
                      {typeof content === 'string' ? <Text>{content}</Text> : content}
                    </GridItem>
                  </Grid>
                ]
            )}
          </StyledBody>
        )
      )}
    </>
  )
}

const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: center;
`
const StyledBody = styled.div`
  > div {
    padding: ${theme.space.xs.value};
  }
  > :nth-child(2n) {
    background-color: ${theme.colors['gray-300'].value};
    border-radius: ${theme.radii.sm.value};
  }
`
