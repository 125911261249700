import { theme } from '@truepill/react-capsule'
import styled, { css } from 'styled-components'

const SecondaryTab = css`
  padding: ${theme.space['2xs'].value};
  border-radius: ${theme.radii.xl.value};
  border: 1px solid ${theme.colors['primary-700'].value};
`

const PrimaryTab = css`
  border-bottom: 1px solid ${theme.colors['primary-700'].value};
`

export const TabWrapper = styled.div<{ variant?: 'primary' | 'secondary'; fullWidth?: boolean }>`
  display: inline-flex;
  align-items: baseline;
  font-weight: 700;
  ${({ fullWidth = false }) => fullWidth && `width: 100%; justify-content: space-between`};
  ${({ variant = 'primary' }) => (variant === 'primary' ? PrimaryTab : SecondaryTab)}
`
