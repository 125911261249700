import { FC, useEffect, useRef, useState } from 'react'
import { Grid, GridItem, TextField } from '@truepill/react-capsule'
import { useAuth0 } from '@auth0/auth0-react'

import usePane from 'hooks/usePane'
import ErrorBoundary from 'components/ErrorBoundary'
import { useNotesContext } from 'providers/NotesProvider'
import { Pane, PaneContentWithScroll } from 'components/Pane'
import { TpButton } from 'components'
import { CustomerNote } from './CustomerNote'
import { useCreateNotesMutation } from '../../generated/graphql'
import { useCustomerContext } from '../../providers/CustomerProvider'

type Props = {
  title?: string
}

export const NotesPanel: FC<Props> = ({ title }) => {
  const { user } = useAuth0()
  const { saveCurrentCustomer } = useCustomerContext()
  const { data: notes, resource_type, resource_id } = useNotesContext()

  const { paneVisible, firstLoad, hidePane } = usePane('Note')
  const hidePaneRef = useRef(hidePane)

  useEffect(() => {
    return hidePaneRef.current
  }, [])

  const [noteText, setNoteText] = useState('')
  const [errorText, setErrorText] = useState('')
  const [isFocused, setFocus] = useState(false)
  const [createNote] = useCreateNotesMutation({ refetchQueries: ['getNotes'] })

  const postNote = () => {
    if (noteText.trim().length < 3) return setErrorText('Min length 3')
    createNote({
      variables: {
        comment: noteText,
        client: saveCurrentCustomer.customerSlug,
        resource_type,
        resource_id,
        author_name: user?.name || '',
      },
    })
    setErrorText('')
    setNoteText('')
    setFocus(false)
  }

  const handleClose = () => {
    setErrorText('')
    setNoteText('')
    setFocus(false)
    hidePane()
  }

  return (
    <ErrorBoundary>
      <Pane firstLoad={firstLoad} visible={paneVisible} onClose={handleClose} title={title ?? 'Notes'}>
        <Grid justifyContent="end">
          <GridItem desktop={12} tablet={8}>
            <TextField
              type="text"
              size="sm"
              placeholder="Enter note"
              value={noteText}
              state={errorText ? 'error' : 'default'}
              helperText={errorText}
              onFocus={() => setFocus(true)}
              onChange={e => setNoteText(e.target.value)}
              onKeyDown={event => event.key === 'Enter' && postNote()}
            />
          </GridItem>
          {isFocused && (
            <>
              <GridItem desktop={9} tablet={6} />
              <GridItem desktop={3} tablet={2}>
                <TpButton onClick={postNote}>Post</TpButton>
              </GridItem>
            </>
          )}

          <GridItem desktop={12} tablet={8}>
            <PaneContentWithScroll>
              {notes?.map(note => (
                <CustomerNote note={note} key={`note-${note.id}`} />
              ))}
            </PaneContentWithScroll>
          </GridItem>
        </Grid>
      </Pane>
    </ErrorBoundary>
  )
}
