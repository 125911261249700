import { FC } from 'react'
import { Spacer, Text, theme } from '@truepill/react-capsule'
import { formatStringTimeStamp } from '../../utils'
import type { Note } from '../../generated/graphql'

type Props = {
  note: Note
}

export const CustomerNote: FC<Props> = ({ note }) => {
  const { comment, author_name, createdAt } = note
  const formatCreatedAt = formatStringTimeStamp(createdAt)

  return (
    <>
      <Spacer size="md" />
      <Text as="span" css={{ fontWeight: 700 }}>
        {author_name}{' '}
      </Text>
      <Text as={'span'} css={{ color: theme.colors['typography-medium'].value }}>
        {formatCreatedAt}
      </Text>
      <Text>{comment}</Text>
    </>
  )
}
