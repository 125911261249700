import { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react'

import { Toast } from 'libs/toast/Toast'

export enum ToastMessageType {
  'error' = 'ERROR',
  'info' = 'INFO',
  'success' = 'SUCCESS',
}

export interface ToastData {
  message: string
  id: string
  type: ToastMessageType
}

export interface ToastProviderProps {
  toastSlices: ToastData[]
  dismissToast: (id: string) => void
  showToast: (message: string, type: ToastMessageType) => void
}

export const ToastContext = createContext<Partial<ToastProviderProps>>({
  toastSlices: [],
})

type Props = {
  children: ReactNode
}

export const useToastContext = () => useContext(ToastContext)

export const ToastProvider: FC<Props> = ({ children }) => {
  const [toastSlices, setToastSlices] = useState<ToastData[]>([])

  const slicesRef = useRef(toastSlices)
  const setSlicesRef = useRef(setToastSlices)
  useEffect(() => {
    slicesRef.current = toastSlices
    setSlicesRef.current = setToastSlices
  }, [toastSlices, setToastSlices])

  const value = {
    toastSlices,
    dismissToast: (dismissToastId: string) => {
      const newSlices = [...slicesRef.current]
      const toastIndex = newSlices.findIndex(({ id }) => id === dismissToastId)
      newSlices.splice(toastIndex, 1)
      setSlicesRef.current(newSlices)
    },
    showToast: (message: string, toastType: ToastMessageType) => {
      const newToast: ToastData = {
        message,
        type: toastType,
        id: new Date().getTime() + message,
      }
      setSlicesRef.current([newToast, ...slicesRef.current])
    },
  }

  return (
    <ToastContext.Provider value={value}>
      <Toast />
      {children}
    </ToastContext.Provider>
  )
}
