import { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor, bodySecondaryColor, primaryColorLight } from 'styles/styleVariables'

const Highlighted = css`
  background-color: ${primaryColorLight};
  > p,
  p:not(:first-of-type) {
    color: ${bodyPrimaryColor};
  }
  > div {
    color: ${bodyPrimaryColor};
  }
`
interface DropDownOptionProps extends HTMLAttributes<HTMLLIElement> {
  active?: boolean
  disabled?: boolean
  selected?: boolean
  highlighted?: boolean
  isModal?: boolean
  className?: string
}

const DropDownOption: FC<DropDownOptionProps> = props => {
  const htmlProps = { ...props, highlighted: props.highlighted ? 'true' : 'false' }
  return (
    <li className={props.className} {...htmlProps}>
      {props.children}
    </li>
  )
}

export const StyledDropDownOption = styled(DropDownOption)`
  ${({ disabled }) => (disabled ? `cursor: default` : `cursor: pointer`)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  color: ${bodyPrimaryColor};
  flex-grow: 1;
  :hover {
    ${Highlighted}
  }
  ${({ isModal }) => (isModal ? 'z-index: 9' : 'z-index: 1')}
  > p {
    color: ${bodySecondaryColor};
    font-size: 0.875rem;
    font-weight: normal;
    text-transform: capitalize;
  }
  > p:not(:first-of-type) {
    color: ${bodySecondaryColor};
  }
  > :first-child {
    text-transform: capitalize;
    color: ${bodyPrimaryColor};
    font-weight: 500;
    font-size: 1rem;
  }
  ${({ disabled, highlighted }) => highlighted && !disabled && Highlighted}
`

export { StyledDropDownOption as DropDownOption }
