export default function formatPhoneNumber(phoneNumberString?: string | null): string {
  if (!phoneNumberString) {
    return 'N/A'
  }

  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ')', ' ', match[3], '-', match[4]].join('')
  }

  return phoneNumberString
}

export const formatPhoneForDB = (inputPhone?: string): string => {
  if (!inputPhone) {
    return ''
  }

  let returnVal = inputPhone.replace(/[^0-9]/g, '')
  returnVal = returnVal.slice(0, 3) + '-' + returnVal.slice(3, 6) + '-' + returnVal.slice(6)
  return returnVal.replaceAll(' ', '')
}
