import { theme } from '@truepill/react-capsule'
import { FC } from 'react'
import styled from 'styled-components'

export const CustomerLogo: FC<{ path: string; label: string }> = ({ path, label }) => {
  return <StyledLogo src={path} alt={label} />
}

const StyledLogo = styled.img`
  height: ${theme.space['2xl'].value};
  max-width: fit-content;
  object-fit: contain;
  margin-right: ${theme.space.xs.value};
`
