import React, { FC, ReactNode } from 'react'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { SideToolBar } from './SideToolBar'

type Props = {
  children: ReactNode
  paddingTop?: boolean
}

export const ToolBar: FC<Props> = ({ children, paddingTop = false }) => {
  const [editMode] = useQueryParam('editMode', BooleanParam)

  return (
    <SideToolBar paddingTop={paddingTop} visible={!editMode}>
      {children}
    </SideToolBar>
  )
}
