import { FC, ReactNode, useContext } from 'react'
import { ModalContext, ModalProviderProps } from 'providers/ModalProvider'
import { Grid, GridItem, Header, Modal, Spacer } from '@truepill/react-capsule'

import { TpButton } from './Buttons'

interface ModalProps {
  confirmationCallback?: () => void
  cancelCallback?: () => void
  title?: string | ReactNode
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  constrainWidth?: boolean
  hideCancel?: boolean
  disabled?: boolean
  loading?: boolean
  hideFooter?: boolean
}

export const SimpleModal: FC<ModalProps> = ({
  title,
  confirmButtonLabel = 'OK',
  cancelButtonLabel = 'Cancel',
  confirmationCallback,
  cancelCallback,
  hideCancel,
  children,
  disabled,
  loading,
  hideFooter,
}) => {
  const { dismissModal } = useContext(ModalContext) as ModalProviderProps

  return (
    <Modal isOpen={true} onDismiss={dismissModal} aria-label="modal-dialog">
      <Grid spacing="md">
        <GridItem desktop={12} tablet={8} mobile={4}>
          <Header variant="4xl">{title}</Header>
        </GridItem>
        <GridItem desktop={12} tablet={8} mobile={4}>
          {children}
        </GridItem>
        {!hideFooter && (
          <GridItem desktop={12} tablet={8} mobile={4}>
            <Grid spacing="none" css={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!hideCancel && (
                <TpButton
                  variant={'primary-text'}
                  onClick={() => {
                    dismissModal()
                    cancelCallback && cancelCallback()
                  }}
                >
                  {cancelButtonLabel}
                </TpButton>
              )}
              <Spacer />
              <TpButton
                loading={loading}
                disabled={disabled || loading}
                onClick={() => {
                  confirmationCallback && confirmationCallback()
                }}
              >
                {confirmButtonLabel}
              </TpButton>
            </Grid>
          </GridItem>
        )}
      </Grid>
    </Modal>
  )
}
