import { createContext, FC, ReactNode, useMemo } from 'react'
import { DEFAULT_CONTEXT, searchOptions } from './constants'
import { CustomerProviderProps } from './types'
import { useCurrentCustomer } from './useCurrentCustomer'

export const CustomerContext = createContext<CustomerProviderProps>(DEFAULT_CONTEXT)

type Props = {
  children: ReactNode
}
export const CustomerProvider: FC<Props> = ({ children }) => {
  const { saveCurrentCustomer, handleChangeCustomer, customerUiConfig, customerCollection, customersMap } =
    useCurrentCustomer()

  const optionByCustomer = useMemo(() => {
    return searchOptions.filter(
      option => customerUiConfig?.searchEnabled && customerUiConfig?.searchEnabled[option.value]
    )
  }, [customerUiConfig])

  const value: CustomerProviderProps = {
    saveCurrentCustomer,
    handleChangeCustomer,
    customerCollection,
    customersMap,
    customerUiConfig,
    optionByCustomer,
  }

  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>
}
