import { Component } from 'react'
import LogRocket from 'logrocket'

export default class ErrorBoundary extends Component<Record<string, unknown>, { hasError: boolean }> {
  constructor(props: Record<string, unknown>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { componentStack } = errorInfo
    LogRocket.captureException(error, {
      extra: { componentStack },
    })
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong</h1>
    }

    return this.props.children
  }
}
