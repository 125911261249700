import { FC } from 'react'
import { Theme } from '@truepill/react-capsule'

import PaneProvider from './providers/PaneProvider'
import { ModalProvider } from './providers/ModalProvider'
import { ToastProvider } from './libs/toast'

import { GlobalStyle } from './components'
import { capsuleOverrides } from './styles'
import { Router } from './routes'

export const App: FC = () => {
  return (
    <Theme overrides={capsuleOverrides}>
      <GlobalStyle />
      <ModalProvider>
        <ToastProvider>
          <PaneProvider>
            <Router />
          </PaneProvider>
        </ToastProvider>
      </ModalProvider>
    </Theme>
  )
}
