import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Grid, GridItem, Header, theme } from '@truepill/react-capsule'
import { Helmet } from 'react-helmet-async'

interface PageProps {
  title: string
  meta?: ReactNode
  header?: string | boolean
  children: ReactNode
}

export const Page: FC<PageProps> = props => {
  const { title, meta, header = false, children } = props

  return (
    <>
      <Helmet>
        <title>{`${title} | TPCS`}</title>
        {meta}
      </Helmet>

      <StyledWrap>
        {header && (
          <GridItem mobile={4} tablet={8} desktop={12}>
            <Header variant="4xl" as={'h1' as React.ElementType} bold>
              {title}
            </Header>
          </GridItem>
        )}

        <GridItem mobile={4} tablet={8} desktop={12}>
          {children}
        </GridItem>
      </StyledWrap>
    </>
  )
}

const StyledWrap = styled(Grid)`
  padding-bottom: ${theme.space['2xl'].value};
`
