import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

/**
 * This hook will call the callback given to it once each time that the user
 * navigates to a different path.
 */
export default function useNavigation(callback: () => void) {
  const location = useLocation()
  const lastPathName = usePrevious(location.pathname)

  useEffect(() => {
    if (lastPathName && location.pathname !== lastPathName) {
      callback()
    }
  }, [callback, lastPathName, location.pathname])
}
