export const orderTypeFormatter = (callbackUrl: string | null | undefined) => {
  if (callbackUrl === null || !callbackUrl) {
    return { full: 'Store', short: 'Store' }
  } else if (callbackUrl.indexOf('ovc-appointment') > -1) {
    return { full: 'Sync Live Care', short: 'SLC' }
  } else if (callbackUrl.indexOf('virtual-care/payment-update') > -1) {
    return { full: 'Async Care', short: 'AC' }
  } else if (
    callbackUrl.indexOf('pharmacy/orders/payment-update') > -1 ||
    callbackUrl.indexOf('pharmacy/orders/auto-refill-payment-update') > -1
  ) {
    return { full: 'Virtual Pharmacy', short: 'VP' }
  } else {
    return { full: 'Mental Health', short: 'MH' }
  }
}
