import { CSSProperties, forwardRef, Ref } from 'react'
import styled, { keyframes } from 'styled-components'

const SIZE = 44

interface ICircularProgressProps {
  /**
   * The size of the component.
   * If using a number, the pixel unit is assumed.
   * If using a string, you need to provide the CSS unit, e.g '3rem'.
   * @default 40
   */
  size?: number
  style?: CSSProperties
}

export const CircularProgress = forwardRef(({ size = 40, style }: ICircularProgressProps, ref: Ref<HTMLDivElement>) => {
  const thickness = 3.6

  return (
    <CircularProgressRoot style={{ width: size, height: size, ...style }} ref={ref}>
      <CircularProgressSVG viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
        <CircularProgressCircle cx={SIZE} cy={SIZE} r={(SIZE - thickness) / 2} fill="none" strokeWidth={thickness} />
      </CircularProgressSVG>
    </CircularProgressRoot>
  )
})

const circularRotateKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const CircularProgressRoot = styled.span`
  display: inline-block;
  animation: ${circularRotateKeyframe} 1.4s linear infinite;
`

const CircularProgressSVG = styled.svg`
  display: block;
`

const CircularProgressCircle = styled.circle`
  stroke: currentcolor;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
`
