import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { Logo } from '../components'

const StyledHeader = styled('header')({
  position: 'absolute',
  top: 20,
  left: 20,
  width: '100%',
  padding: 'var(--cap-spacing-xs)',
  lineHeight: 0,
})

export const LogoOnlyLayout: FC = () => {
  return (
    <>
      <StyledHeader>
        <Logo />
      </StyledHeader>
      <Outlet />
    </>
  )
}
