import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html{
    width: 100%;
    font-family: var(--cap-fonts-base);

    --webkit-overflow-scrolling: touch;
  }
  body, #root {
    width: 100%;
    height: 100%;
  }
  a {
    font-family: var(--cap-fonts-base);
  }
`
