import React, { ComponentProps, FC } from 'react'
import styled from 'styled-components'
import { Search as SearchIcon } from 'react-feather'
import { TextField, theme } from '@truepill/react-capsule'

type Props = ComponentProps<typeof TextField> & { onEnter?: VoidFunction }

export const FieldSearch: FC<Props> = ({ placeholder = 'Search...', onEnter, ...props }) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (onEnter) {
        onEnter()
      }
    }
  }

  return (
    <StyledRootWrapper size={props.size}>
      <TextField
        state="default"
        size="sm"
        type="search"
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        {...props}
      />
      <StyledIconWrapper size={props.size}>
        <SearchIcon size={18} />
      </StyledIconWrapper>
    </StyledRootWrapper>
  )
}

const StyledIconWrapper = styled.div<{ size: 'sm' | 'lg' | undefined }>`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: ${({ size }) => (size ? (size === 'sm' ? '15px' : '20px') : '15px')};
  left: 15px;
  pointer-events: none;
`

const StyledRootWrapper = styled.div<{ size: 'sm' | 'lg' | undefined }>`
  position: relative;
  display: flex;
  input[type='search'] {
    padding-left: 44px;
    padding-right: 12px;
    font-family: ${theme.fonts.base.value};
    line-height: ${({ size }) => (size ? (size === 'sm' ? '48px' : '56px') : '48px')};
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='20' height='20' stroke='currentColor' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
    }
  }
`
