import { FULL_URL_VAR, PARTIAL_URL_VAR } from 'config'

const getBackendUrl = () => {
  /**
   * This is used by VisionRouter (`yarn sandbox`) and the Cypress testing code (`yarn sandbox:test`).
   * It's also usable by directly setting either variable before a yarn command.
   */
  if (FULL_URL_VAR) {
    return FULL_URL_VAR
  }

  switch (PARTIAL_URL_VAR) {
    case 'LOCAL':
      return 'http://localhost:3000/graphql'
  }
  if (PARTIAL_URL_VAR) {
    return `https://vision.${PARTIAL_URL_VAR}.falsepill.com/graphql`
  } else {
    // fall back to the graphql url of the current server
    const location = window.location
    return `${location.protocol}//${location.host}/graphql`
  }
}

const getBackendUrlHelp = () => {
  return (
    `To specify a backend, set an environment variable (directly or via yarn command):\n` +
    `* ${PARTIAL_URL_VAR}=<name with no dots> --> use ephemeral environment at https://vision.$\{name}.falsepill.com>\n` +
    `* ${PARTIAL_URL_VAR}=DOCKER|LOCAL|PROD|PRODUCTION|SANDBOX|UAT --> use predefined existing environment\n` +
    `* ${FULL_URL_VAR}=<full URL, including http[s]:// and /graphql>\n` +
    ``
  ).trimRight()
}
export { getBackendUrl, getBackendUrlHelp }
