import { CSSProperties, useMemo, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuProps,
  MultiValueProps,
  OptionProps,
  StylesConfig,
  Theme,
  ThemeConfig,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import { ChevronDown as DropDownIcon, X as CloseIcon } from 'react-feather'
import { IComponentsProps, IStylesProps, IThemeProps } from './types'
import { LoadingSpinner, Text, theme } from '@truepill/react-capsule'
import { ReactSelectProps } from 'react-select/dist/declarations/src/Select'

export type Option = { label: string; value: string }

const getSelectStyles = <T,>({ error, size, isDisabled }: IStylesProps): StylesConfig<T, boolean, GroupBase<T>> => ({
  control: (base, state) => ({
    ...base,
    cursor: 'pointer',
    backgroundColor: error
      ? theme.colors['functional-error-light'].value
      : isDisabled
      ? theme.colors['gray-100'].value
      : theme.colors.white.value,
    width: '100%',
    borderWidth: '1px',
    borderColor: error
      ? theme.colors['functional-error-dark'].value
      : state.isFocused
      ? 'transparent'
      : isDisabled
      ? theme.colors['gray-500'].value
      : theme.colors['gray-700'].value,
    boxShadow: state.isFocused
      ? `inset 0px 0px 0px 3px ${
          error
            ? theme.colors['functional-error-dark'].value
            : state.isFocused
            ? theme.colors['primary-500'].value
            : theme.colors['gray-700'].value
        }`
      : 'none',
    boxSizing: 'border-box',
    margin: '0',
    height: size === 'md' ? '56px' : '48px',
    fontFamily: theme.fonts.base.value,
    borderRadius: theme.radii.sm.value,
  }),
  singleValue: base => ({
    ...base,
    overflow: 'visible',
    marginLeft: '0rem',
    color: theme.colors['typography-dark'].value,
  }),

  valueContainer: base => ({
    ...base,
    overflow: 'hidden',
    padding: '0 12px',
    color: theme.colors['typography-dark'].value,
    height: size === 'md' ? '56px' : '48px',
    flexWrap: 'nowrap',
  }),
  input: base => ({
    ...base,
    overflow: 'hidden',
    marginLeft: '0rem',
    color: theme.colors['typography-dark'].value,
    fontFamily: theme.fonts.base.value,
  }),
  placeholder: base => ({
    ...base,
    padding: '0.52rem 0',
    marginLeft: '0rem',
    color: theme.colors['typography-medium'].value,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: theme.fonts.base.value,
  }),
  multiValueLabel: base => ({ ...base, textTransform: 'uppercase' }),
  multiValueRemove: base => ({ ...base, color: theme.colors['secondary-700'].value }),
  menu: base => ({
    ...base,
    backgroundColor: theme.colors.white.value,
    zIndex: 5,
    boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 4px, rgb(50 50 93 / 22%) 0px 6px 8px',
    borderRadius: theme.radii.sm.value,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 0,
    transition: 'all 0.3s',
    transform: !error ? (state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)') : 'rotate(0deg)',
  }),
  loadingIndicator: base => ({ ...base, position: 'absolute', marginLeft: '-3rem' }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? theme.colors['primary-300'].value : '',
    color: theme.colors['typography-dark'].value,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.fontSizes.sm.value,
    alignItems: 'center',
    fontFamily: theme.fonts.base.value,
    cursor: 'pointer',
  }),
})

const getTheme =
  ({ error }: IThemeProps): ThemeConfig =>
  (selectTheme: Theme) => ({
    ...selectTheme,
    colors: {
      ...selectTheme.colors,
      primary: error ? theme.colors.white.value : theme.colors['primary-300'].value,
      primary25: theme.colors['primary-300'].value,
      primary50: theme.colors['primary-300'].value,
      neutral10: theme.colors['primary-100'].value,
      danger: theme.colors['functional-error-dark'].value,
      dangerLight: theme.colors['functional-error-light'].value,
    },
    spacing: { baseUnit: 7, controlHeight: 0, menuGutter: 0 },
  })

const getComponents = <T,>({ error, limitToShow }: IComponentsProps) => ({
  LoadingIndicator: () => (
    <StyledLoaderWrapper>
      <LoadingSpinner />
    </StyledLoaderWrapper>
  ),
  DropdownIndicator: (props: DropdownIndicatorProps<T>) => {
    return (
      <components.DropdownIndicator {...props}>
        {error && <></>}
        <StyledDropwDownIconContainer>
          <DropDownIcon color={theme.colors['typography-medium'].value} />
        </StyledDropwDownIconContainer>
      </components.DropdownIndicator>
    )
  },
  IndicatorSeparator: () => null,
  MultiValue: (props: MultiValueProps<T>) => {
    const overflowAmount = props.getValue().slice(limitToShow).length
    const moreSelectedStyle = {
      ...props.getStyles('multiValue', props),
      ...props.getStyles('multiValueLabel', props),
    } as CSSProperties

    if (props.index < limitToShow) {
      return <components.MultiValue {...props} />
    }

    return props.index === limitToShow ? <span style={moreSelectedStyle}>+{overflowAmount}</span> : null
  },
  Option: (props: OptionProps<T>) => {
    return (
      <components.Option {...props}>
        {props.children}
        {props.isMulti && props.isSelected && <StyledCloseIcon />}
      </components.Option>
    )
  },
  Menu: (props: MenuProps<T>) => (
    <MenuWrapper>
      <components.Menu {...props} />
    </MenuWrapper>
  ),
})

export const TpSelect = <T,>({
  error,
  value,
  options,
  size = 'sm',
  loadOptions,
  isClearable = false,
  limitToShow = 1,
  menuPortalTarget = null,
  menuPlacement = 'auto',
  menuPosition = 'absolute',
  classNamePrefix = 'tp-select',
  label,
  isDisabled,
  ...props
}: ReactSelectProps<T>) => {
  const [sortedOptions, setSortedOptions] = useState<typeof options>(options)
  const selectCommonProps = {
    isClearable,
    menuPortalTarget,
    menuPlacement,
    menuPosition,
    classNamePrefix,
    // add defaultOptions property for async select
    ...(loadOptions && Array.isArray(value) ? { defaultOptions: value } : {}),
    onMenuOpen: () => {
      setSortedOptions(_sortOptions(value, options))
    },
  }

  const SelectStyles = useMemo(() => getSelectStyles<T>({ error, size, isDisabled }), [error, size, isDisabled])

  const SelectComponents = useMemo(() => getComponents<T>({ error, limitToShow }), [error, limitToShow])

  const SelectTheme = useMemo(() => getTheme({ error }), [error])

  const _sortOptions = (selectedOptions: typeof value, optionsList?: typeof options) => {
    if (!optionsList) {
      return []
    }

    if (selectedOptions && Array.isArray(selectedOptions)) {
      const stateOptions = optionsList.filter(
        option => !selectedOptions.find(selected => props.getOptionValue(selected) === props.getOptionValue(option))
      )

      return selectedOptions.concat(stateOptions)
    }

    return optionsList
  }

  return (
    <div>
      {label && (
        <StyledLabel>
          <label>
            <span>{label}</span>
          </label>
        </StyledLabel>
      )}
      {loadOptions ? (
        <AsyncSelect
          value={value}
          loadOptions={loadOptions}
          theme={SelectTheme}
          styles={SelectStyles}
          components={SelectComponents}
          isDisabled={isDisabled}
          {...selectCommonProps}
          {...props}
        />
      ) : (
        <Select
          value={value}
          options={sortedOptions}
          theme={SelectTheme}
          styles={SelectStyles}
          components={SelectComponents}
          className="tp-select"
          isDisabled={isDisabled}
          {...selectCommonProps}
          {...props}
        />
      )}

      {error && (
        <StyledErrorWrapper>
          <Text variant="body-sm" css={{ color: theme.colors['functional-error-dark'].value }}>
            {error}
          </Text>
        </StyledErrorWrapper>
      )}
    </div>
  )
}

const StyledErrorWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: ${theme.space.xs.value};
`

const StyledLabel = styled.div`
  padding-bottom: ${theme.space.sm.value};
  label {
    font-weight: bold;
    font-family: ${theme.fonts.base.value};
    font-size: ${theme.fontSizes.md.value};
    line-height: 1.375;
    span {
      white-space: nowrap;
    }
  }
`

const StyledLoaderWrapper = styled.div`
  margin: 0.75rem;
`

const StyledDropwDownIconContainer = styled.div`
  margin: 0.75rem;
`

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: auto;
  cursor: pointer;
  fill: ${theme.colors['typography-dark'].value};
`

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const MenuWrapper = styled.div`
  animation: ${slideDown} 0.2s ease-in-out;
  position: relative;
  z-index: 999;
`
