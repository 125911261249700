import { createContext, FC, ReactNode, useContext, useMemo } from 'react'

import { UserInfoFragment } from '../generated/graphql'

const initialState: UserInfoFragment = {
  permissions: [],
}

export const UserInfoContext = createContext<UserInfoFragment>(initialState)

type Props = {
  userInfo: UserInfoFragment | null
  children: ReactNode
}

export const UserInfoProvider: FC<Props> = ({ userInfo, children }) => {
  const getUserInfo = useMemo(
    () => ({
      ...userInfo,
      permissions: userInfo?.permissions || [],
    }),
    [userInfo]
  )

  return <UserInfoContext.Provider value={getUserInfo}>{children}</UserInfoContext.Provider>
}

export const useUserInfo = (): UserInfoFragment => {
  return useContext(UserInfoContext)
}
