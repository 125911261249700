import { ChangeEvent, ComponentProps, FC } from 'react'
import type { Control } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox as CapsuleCheckbox, TextField as CapsuleTextField } from '@truepill/react-capsule'
import { InputType } from '@truepill/react-capsule/dist/types'

import { formatErrorMessage } from 'utils/textFormatting'
import { FieldSearch, Option, TpSelect } from 'components'

interface BaseFieldType {
  control?: Control
  name: string
  onChange?: (e: ChangeEvent<any>) => void
}
type OmitedCapsuleTextField = 'type' | 'value' | 'onChange' | 'name' | 'onBlur'
type OmitedSelect = 'getOptionValue' | 'getOptionLabel' | 'options'
interface ITextField
  extends Partial<Omit<ComponentProps<typeof CapsuleTextField>, OmitedCapsuleTextField>>,
    BaseFieldType {
  type?: InputType
}
interface ICheckBoxField
  extends Partial<Omit<ComponentProps<typeof CapsuleCheckbox>, OmitedCapsuleTextField>>,
    BaseFieldType {
  helperText?: string
}

interface ISelectField
  extends Omit<BaseFieldType, 'onChange'>,
    Omit<ComponentProps<typeof TpSelect>, OmitedCapsuleTextField | OmitedSelect> {
  options: Option[]
  onChange?: (e: string) => void
  helperText?: string
}
export const TextField: FC<ITextField> = ({
  name,
  type = 'text',
  helperText,
  size = 'sm',
  label,
  ref,
  placeholder,
}) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return type === 'search' ? (
          <FieldSearch
            {...field}
            label={label}
            ref={ref}
            placeholder={placeholder}
            size={size}
            type={type}
            state={error ? 'error' : 'default'}
            helperText={error ? helperText || formatErrorMessage(error.message) : helperText}
          />
        ) : (
          <CapsuleTextField
            {...field}
            label={label}
            placeholder={placeholder}
            size={size}
            type={type}
            ref={ref}
            state={error ? 'error' : 'default'}
            helperText={error ? helperText || formatErrorMessage(error.message) : helperText}
          />
        )
      }}
    />
  )
}
export const Select = ({ name, options, helperText, onChange, ...selectProps }: ISelectField) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { value, onChange: fieldChange, ref, ...field }, fieldState: { error } }) => {
        const selectedValue = options.find(v => v.value === value)
        return (
          <TpSelect
            {...field}
            {...selectProps}
            value={selectedValue}
            options={options}
            getOptionLabel={l => l.label}
            getOptionValue={v => v.value}
            onChange={val => {
              onChange && onChange(val.value)
              fieldChange(val.value)
            }}
            error={error ? helperText || formatErrorMessage(error.message) : helperText}
          />
        )
      }}
    />
  )
}

export const Checkbox: FC<ICheckBoxField> = ({ name, label, helperText, onChange, ...checkBoxProps }) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: fieldChange, value, ...otherProps }, fieldState: { error } }) => {
        return (
          <CapsuleCheckbox
            {...otherProps}
            {...checkBoxProps}
            checked={value}
            onCheckedChange={(val: any) => {
              onChange && onChange(val)
              fieldChange(val)
            }}
            label={error ? helperText || formatErrorMessage(error.message) : label}
            state={error ? 'error' : 'default'}
          />
        )
      }}
    />
  )
}
