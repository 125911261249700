import { useCallback, useContext } from 'react'

import { ToastContext, ToastMessageType, ToastProviderProps } from './ToastProvider'

type TToast = {
  message: string
  type?: keyof typeof ToastMessageType
}
export const useToast = () => {
  const { showToast } = useContext(ToastContext) as ToastProviderProps

  return useCallback(
    ({ message, type = 'success' }: TToast) => {
      showToast(message, ToastMessageType[type])
    },
    [showToast]
  )
}
